<template>
  <b-modal
    ref="partnershipDetailsModal"
    hide-footer
    title="Add Upcoming Classes"
    centered
    lazy
    scrollable
    @hide="hideModal"
    size="lg"
  >
    <div class="text-center">
      <h3>Interested in an easy way to sign up students?</h3>

      <p>
        Interested in an easy way to sign up students? Promote your upcoming classes across all your partnerships
        channels, catching the eye of individuals interested in enrolling in healthcare training. Subscribers will get
        monthly alerts about your classes, effortlessly attracting more students and expanding your reach. Check out the
        video below for more details.
      </p>
      <div class="my-2">
        <video
          style="object-fit: fill"
          width="100%"
          height="350"
          controls
          muted
          src="https://transition-prod.s3.amazonaws.com/resources/Upcoming+Classes.mp4"
        ></video>
      </div>
      <div class="d-flex">
        <b-btn
          class="ml-auto"
          :variant="reqDemo ? 'success' : 'primary'"
          :disabled="reqDemo"
          @click.prevent="schoolDemoRequest"
        >
          <md-icon v-if="reqDemo" style="font-size: 17px" class="mr-1">check</md-icon>
          {{ reqDemo ? 'Demo requested' : 'Request Demo' }}
        </b-btn>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'AddUpcomingClassesModal',

  props: {
    showModal: { type: Boolean, default: false },
    reqDemo: { type: Boolean, default: false },
  },

  data() {
    return {};
  },

  watch: {
    showModal(value) {
      if (value) {
        this.$refs.partnershipDetailsModal.show();
      }
    },
  },
  methods: {
    ...mapActions('school', ['requestDemo']),

    async schoolDemoRequest() {
      window.open('https://calendly.com/jointransition/transitionenroll-demo', '_blank');
      try {
        const data = {
          demo_request: true,
        };
        await this.requestDemo(data);
        this.makeToast({ variant: 'success', msg: 'Demo has been requested' });

        this.hideModalManual();
      } catch (error) {
        //
      }
    },

    hideModal() {
      this.$emit('close');
    },

    hideModalManual() {
      this.$refs.partnershipDetailsModal.hide();
      this.hideModal();
    },
  },

  async mounted() {},
};
</script>
