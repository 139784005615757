<template>
  <div>
    <page-header :title="title" :container-class="containerClass" />
    <div class="page-section">
      <div :class="containerClass">
        <div class="card p-2 mb-2" style="color: black; background-color: rgb(246 246 246)" v-if="!purchaseSuccess">
          <div class="p-2">
            <p><b>Why Tokens?</b> Tokens not only gives you access to a pool of talented individuals but also supports the educational journey of these aspiring professionals as a donation
              . Plus, every unlock will be promoted, strengthening your brand and fostering a deeper relationship with the school. Invest in Tokens, and you're investing in the future of healthcare.</p>

          </div>
        </div>
        <div class="d-flex justify-content-center" v-if="purchaseSuccess">
          <div class="card card-block card-stretch card-height col-12">
            <div class="card-body text-center">
              <i class="fas fa-check-circle fa-lg text-success mt-4 mb-4" style="font-size: 3rem" />
              <h4>Tokens Purchased</h4>

              <p class="text-muted">
                <b>{{ purchasedTokens }} TT</b> successfully purchased and added to your account. Go to home to spend
                your Tokens.
              </p>

              <router-link :to="{ name: 'home' }" class="btn btn-primary" exact
                ><md-icon class="icon-16pt mr-1">home</md-icon> Back to Home
              </router-link>
            </div>
          </div>
        </div>


        <div class="row card-group-row mb-16pt mb-lg-40pt" v-else>
          <div class="col-lg-3 col-sm-6 card-group-row__col" v-for="pkg in packages" :key="pkg.abbreviation">
            <tokens-package-card
              :title="pkg.title"
              :description="pkg.description"
              :price="pkg.price"
              :tokens="pkg.tokens"
              :color="pkg.raised === true ? 'primary' : 'secondary'"
              :action-color="pkg.raised === true ? null : 'outline-secondary'"
              :ribbon="pkg.ribbon"
              :raised="pkg.raised"
              :job-credits="pkg.job_credits"
              :package="pkg"
              :action-disabled="isLoading"
              :is-loading="isLoading"
              class="mb-lg-0 border-0"
              @onAction="purchaseCredits"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { get } from 'lodash';

import TokensPackageCard from './TokensPackageCard.vue';
import PageHeader from '../../components/Ui/PageHeader.vue';
import Page from '@/components/Page.vue';
import { PAYMENT_STATUSES } from '../../common/constants';

export default {
  components: { PageHeader, TokensPackageCard },
  extends: Page,
  name: 'PurchaseTokens',

  data() {
    return {
      title: 'Purchase Tokens',

      packages: [
        // {
        //   title: 'Basic',
        //   abbreviation: 'basic',
        //   description: 'Get Transition Tokens without breaking the bank',
        //   price: 99,
        //   tokens: 30,
        //   color: 'secondary',
        //   actionColor: 'outline-secondary',
        //   ribbon: null,
        //   raised: false,
        //   jobCredits: 1,
        // },
        // {
        //   title: 'Silver',
        //   abbreviation: 'silver',
        //   description: 'Buy Transition Tokens for daily use',
        //   price: 179,
        //   tokens: 60,
        //   color: 'primary',
        //   actionColor: null,
        //   ribbon: 'Common',
        //   raised: true,
        //   jobCredits: 2,
        // },
        // {
        //   title: 'Gold',
        //   abbreviation: 'gold',
        //   description: 'Access great features with more Transition Tokens',
        //   price: 299,
        //   tokens: 120,
        //   color: 'secondary',
        //   actionColor: 'outline-secondary',
        //   ribbon: null,
        //   raised: false,
        //   jobCredits: 4,
        // },
        // {
        //   title: 'Platinum',
        //   abbreviation: 'platinum',
        //   description: 'Transition Tokens at best value',
        //   price: 499,
        //   tokens: 240,
        //   color: 'secondary',
        //   actionColor: 'outline-secondary',
        //   ribbon: 'Best Value',
        //   raised: false,
        //   jobCredits: 8,
        // },
      ],

      isLoading: false,
      purchaseSuccess: false,
      purchasedTokens: 0,
    };
  },

  computed: {
    breadcrumb() {
      return [
        { text: this.$t('home'), to: this.routes.home },
        { text: 'Billing', to: { name: 'manage-billing' } },
        { text: 'Purchase Trasition Tokens', active: true },
      ];
    },
  },

  methods: {
    ...mapActions('credit', [
      'purchaseTokens',
      'updateStateAvailTokens',
      'purchaseTokensPaymentSuccess',
      'getAllPackages',
    ]),

    async fetchAllPackages() {
      try {
        const res = await this.getAllPackages({
          ordering: 'id',
        });

        this.packages = res.data;
      } catch (e) {
        //
      }
    },

    async purchaseCredits(pkg) {
      this.isLoading = true;
      let isStripeRedirecting = false;

      const isConfirmed = await this.$bvModal.msgBoxConfirm(
        `You are about to purchase ${pkg.tokens}TT for $${this.$n(pkg.price)}. Please click 'Continue' to confirm.`,
        {
          title: 'Are you sure?',
          size: 'md',
          buttonSize: 'sm',
          okVariant: 'primary',
          okTitle: 'Continue',
          cancelTitle: 'Cancel',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true,
        }
      );
      if (isConfirmed) {
        try {
          const resp = await this.purchaseTokens({ abbreviation: pkg.abbreviation });

          if (resp.status_code === 200) {
            // If tokens successfully purchased.
            this.updateStateAvailTokens();
            this.purchasedTokens = pkg.tokens;
            this.purchaseSuccess = true;
          } else if (resp.status_code === 303) {
            // If there's no existing payment method, redirect to stripe for adding new payment method.
            isStripeRedirecting = true;
            window.location.href = resp.data.session_url;
          }
        } catch (err) {
          if (get(err, 'response.data.data.status') === PAYMENT_STATUSES.PAYMENT_FAILURE) {
            this.makeToast({
              variant: 'danger',
              msg: 'Cannot complete tokens purchase due to payment issue. Please update your payment method and try again.',
            });
          }
          this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
        }
      }

      this.isLoading = isStripeRedirecting;
    },
  },

  async mounted() {
    await this.fetchAllPackages();

    if (this.$route.query.paymentSuccess) {
      this.isLoading = true;
      const packageId = this.$route.query.packageId;
      const stripeSessionId = this.$route.query.stripeSessionId;
      try {
        await this.purchaseTokensPaymentSuccess({ abbreviation: packageId, session_id: stripeSessionId });
        this.updateStateAvailTokens();
        const pkgFound = this.packages.find((pkg) => pkg.abbreviation === packageId);
        this.purchasedTokens = pkgFound.tokens;
        this.purchaseSuccess = true;
      } catch (err) {
        if (get(err, 'response.data.data.status_msg') === PAYMENT_STATUSES.PAYMENT_FAILURE) {
          this.makeToast({
            variant: 'danger',
            msg: 'Cannot complete tokens purchase due to payment issue. Please update your payment method and try again.',
          });
        } else {
          this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
        }
      }
      this.isLoading = false;
      this.$router.push({ name: 'manage-purchase-tokens' });
    } else if (this.$route.query.paymentFailed) {
      this.isLoading = true;
      try {
        this.makeToast({ variant: 'danger', msg: 'Cannot complete tokens purchase. Please try again!' });
      } catch (_err) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
      this.isLoading = false;
      this.$router.push({ name: 'manage-purchase-tokens' });
    }
  },
};
</script>
