<template>
  <div :class="containerClass">
    <page-header :title="title" infoText="Manage your plans." />
    <div class="page-section">
      <page-separator title="" />
      <div v-if="isLoading">
        <div class="card mb-4">
          <div class="card-header">
            <b-skeleton animation="wave" width="30%"></b-skeleton>
          </div>

          <b-skeleton-table :rows="3" :columns="4"></b-skeleton-table>
        </div>
      </div>

      <div v-if="!plans.length && !isLoading">
        <div class="d-flex justify-content-center">
          <div class="card card-block card-stretch card-height col-md-12">
            <div class="card-body text-center">
              <i class="fas fa-exclamation-circle fa-lg text-muted mt-4 mb-4" style="font-size: 3rem" />
              <h4>No Plans Found</h4>

              <p class="text-muted">No payment plans found.</p>
            </div>
          </div>
        </div>
      </div>

      <div v-else v-for="plan in plans" :key="plan.class_id" class="mb-4">
        <div class="card mb-0">
          <div class="card-header">
            <div>
              <strong style="font-size: 16px"> Class #{{ plan.class_id }} </strong>
            </div>
            <div>
              <strong style="font-size: 16px"> {{ plan.program_name }}</strong>
            </div>
          </div>

          <!-- Plans Table -->
          <b-table
            :fields="tableColumns"
            :items="plan.student_payment_plan"
            head-variant="light"
            class="table-nowrap"
            hover
            responsive
            no-local-sorting
          >
            <template #cell(amount)="data">
              <span> ${{ $n(data.item.amount) }} </span>
            </template>
            <template #cell(date_paid)="data">
              <span> {{ data.item.date_paid ? formatFullDate(data.item.date_paid) : '-' }} </span>
            </template>
            <template #head(actions)="">
              <span></span>
            </template>
            <template #cell(actions)="data">
              <router-link
                :to="
                  data.item.status === STUDENT_PAYMENT_TYPES.CANCELLED && !data.item.status_paid
                    ? ''
                    : {
                        name: 'payment-plan-details',
                        params: { id: data.item.id },
                      }
                "
                href="#"
              >
                <b-badge
                  v-if="
                    (data.item.status === STUDENT_PAYMENT_TYPES.CANCELLED && data.item.status_paid) ||
                    (data.item.status === STUDENT_PAYMENT_TYPES.PAID && data.item.status_paid)
                  "
                  variant="none"
                  class="border border-success text-success"
                >
                  <md-icon style="font-size: 17px">check</md-icon><strong>Paid</strong>
                </b-badge>

                <b-badge
                  v-else-if="data.item.status === STUDENT_PAYMENT_TYPES.CANCELLED && !data.item.status_paid"
                  variant="none"
                  class="border border-danger text-danger"
                >
                  <strong>Cancelled</strong>
                </b-badge>

                <strong v-else class="text-primary"> {{ 'Pay Now' }}</strong>
              </router-link>
            </template>
          </b-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { get } from 'lodash';
import PageHeader from '@/components/Ui/PageHeader.vue';
import Page from '@/components/Page.vue';
import { mapActions, mapGetters } from 'vuex';
import { formatDateTime, formatFullDate } from '@/common/utils';
import { STUDENT_PAYMENT_TYPES } from '@/common/constants';
import PageSeparator from '@/components/Ui/PageSeparator.vue';

export default {
  components: { PageHeader, PageSeparator },
  extends: Page,

  name: 'PlansList',

  data() {
    return {
      title: 'Manage Plans',
      windowWidth: window.innerWidth,
      isLoading: false,
      plans: [],
      searchTerm: '',
      STUDENT_PAYMENT_TYPES,
    };
  },

  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),
    ...mapGetters('school', ['getCurrentSchool']),

    isMobileScreen() {
      return this.windowWidth <= 776;
    },
    tableColumns() {
      return [
        { key: 'amount', label: 'Amount', tdClass: 'clickable-item' },
        {
          key: 'date',
          label: 'Due Date',
          tdClass: 'clickable-item',
          formatter: (value) => (value ? formatFullDate(value) : value),
        },
        {
          key: 'date_paid',
          label: 'Paid On',
          tdClass: 'clickable-item',
          formatter: (value) => (value ? formatFullDate(value) : value),
        },

        {
          key: 'actions',
          tdClass: 'text-right',
          thStyle: { minWidth: '100px' },
        },
      ];
    },
  },

  methods: {
    ...mapActions('payments', ['getHEPaymentPlans']),
    formatDateTime,
    formatFullDate,
    get,

    handleResize() {
      this.windowWidth = window.innerWidth;
    },

    async fetchHEPaymentPlans() {
      this.isLoading = true;
      try {
        const res = await this.getHEPaymentPlans({});

        this.plans = res.data;
      } catch (e) {
        // USE ERROR
      }
      this.isLoading = false;
    },
  },

  async mounted() {
    this.fetchHEPaymentPlans();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
};
</script>
