<template>
  <div>
    <page-header :title="title" :container-class="containerClass" />
    <div class="page-section">
      <div :class="containerClass">
        <div class="card mb-0">
          <div class="card-header">
            <div class="row align-items-center" style="white-space: nowrap">
              <!-- Search -->
              <div class="col-lg-auto">
                <form class="search-form search-form--light d-lg-inline-flex mb-8pt mb-lg-0" @submit.prevent="">
                  <b-form-input
                    class="w-lg-auto"
                    :placeholder="'Search Transactions'"
                    v-model="searchTerm"
                    @input="onSearch"
                  />
                  <b-btn variant="flush" type="submit">
                    <md-icon v-text="'search'" />
                  </b-btn>
                </form>
              </div>
            </div>
          </div>

          <!-- Schools Table -->
          <b-table
            :fields="tableColumns"
            :items="payments"
            :busy="isLoading"
            head-variant="light"
            hover
            responsive
            no-local-sorting
            @sort-changed="onSortChange"
          >
            <template #cell(status)="data">
              <b-badge
                :class="`border border-${CREDIT_TRANS_STATUSES_COLORS[data.item.status]} text-${
                  CREDIT_TRANS_STATUSES_COLORS[data.item.status]
                }`"
                variant="none"
                >{{ $t(`creditTransactionStatuses.${data.item.status}`) }}
              </b-badge>
            </template>

            <template #cell(type)="data"> {{ $t(`creditTransactionTypes.${data.item.type}`) }} </template>

            <template #cell(amount)="data">
              {{ getDisplayableAmount(data.item.amount) }}
            </template>

            <template #cell(created_at)="data"> {{ formatFullDate(data.item.created_at) }} </template>

            <!-- <template #head(actions)="">
              <span></span>
            </template> -->
          </b-table>

          <!-- Footer Pagination -->
          <div class="card-footer">
            <pagination
              v-model="currentPage"
              :total-rows="totalPayments"
              :per-page="perPage"
              @change="onPageChange"
              aria-controls="payments-table"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from '@/components/Ui/PageHeader.vue';
import { debounce } from 'lodash';
import { mapActions, mapGetters } from 'vuex';

import Page from '@/components/Page.vue';
import Pagination from '../../components/Ui/Pagination.vue';
import MdIcon from '../../components/Ui/MdIcon.vue';
import { DEFAULT_PAGE_SIZE, CREDIT_TRANS_STATUSES_COLORS } from '../../common/constants';
import { formatFullDate } from '../../common/utils';

export default {
  components: { MdIcon, PageHeader, Pagination },
  extends: Page,

  name: 'CreditsHistory',

  data() {
    return {
      title: 'Tokens History',
      CREDIT_TRANS_STATUSES_COLORS,

      isLoading: false,

      perPage: DEFAULT_PAGE_SIZE,
      currentPage: 1,

      totalPayments: 0,
      payments: [],

      searchTerm: '',
    };
  },

  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),
    breadcrumb() {
      return [
        { text: this.$t('home'), to: this.routes.home },
        { text: 'Billing', to: { name: 'manage-billing' } },
        { text: 'Tokens History', active: true },
      ];
    },

    tableColumns() {
      return [
        { key: 'id', label: 'Ref. ID' },
        { key: 'status', label: 'Status' },
        { key: 'type', label: 'Type' },
        { key: 'description', label: 'Description' },
        { key: 'amount', label: 'Tokens' },
        { key: 'created_at', label: 'Date' },
      ];
    },
  },

  methods: {
    ...mapActions('credit', ['getCreditTransactions']),
    formatFullDate,

    async fetchCreditTransactions(pageNum = 1, params = {}) {
      this.isLoading = true;
      document.getElementById('app').scrollIntoView();
      let response;

      response = await this.getCreditTransactions({
        limit: this.perPage,
        offset: (pageNum - 1) * this.perPage,
        ...(this.ordering && { ordering: this.ordering }),
        ...(this.searchTerm && { search: this.searchTerm }),
        ...params,
      });

      this.payments = response.data.results;
      this.currentPage = pageNum;
      this.totalPayments = response.data.count;
      this.isLoading = false;
    },

    onPageChange(pageNum) {
      this.fetchCreditTransactions(pageNum);
    },

    onSortChange(context) {
      this.ordering = context.sortDesc ? '-' + context.sortBy : context.sortBy;
      this.fetchCreditTransactions();
    },

    onSearch() {
      this.debouncedSearchTransactions(this);
    },

    debouncedSearchTransactions: debounce((vm) => {
      vm.fetchCreditTransactions();
    }, 500),

    getDisplayableAmount(amount) {
      const absAmount = Math.abs(amount);
      return amount < 0 ? `(${this.$n(absAmount)} TT)` : `${this.$n(absAmount)} TT`;
    },
  },

  async mounted() {
    this.fetchCreditTransactions();
  },
};
</script>
