<template>
  <div :class="containerClass">
    <page-header
      title="Partnership profile"
      :container-class="null"
      class="mb-32pt"
      infoText="Manage school profile for potential partnership with organizations, universities and employers.This profile is displayed on CareCampus and Initiative site"
    />
    <b-form class="col-md-12 px-0 page-section pt-0" @submit.prevent="onSubmit">
      <b-form-group class="row-align-items-center">
        <label class="form-label">
          Banner Image
          <i
            class="material-icons icon-16pt clickable-item text-muted"
            v-b-popover.hover.top="`Ideal dimensions for the logo should be 1280*720.`"
            >info</i
          >
        </label>
        <b-media class="align-items-center" vertical-align="center">
          <fmv-avatar :title="true" rounded size="lg" no-link slot="aside">
            <span v-if="isLogoUploading">...</span>
            <b-img :src="bestSchoolImgUrl" class="img-fluid" width="40" alt="Logo" v-else-if="bestSchoolImgUrl" />
            <i class="fas fa-university fa-lg" v-else></i>
          </fmv-avatar>

          <image-uploader
            @image="e => setImage(e, 'BestSchoolImg')"
            :isUploading="isLogoUploading"
            :isRequired="false"
            placeholder="Select best school image"
          />
        </b-media>
      </b-form-group>

      <b-form-group label="Class Tuition Range" label-for="classTuitionRange" label-class="form-label">
        <div class="row">
          <b-form-group label="From" label-for="fromClassTuition" label-class="form-label" class="col-md-6">
            <currency-input
              id="fromClassTuition"
              v-model="fromClassTuition"
              class="form-control"
              placeholder="Enter tuition fee range"
              :allow-negative="false"
              :currency="{ prefix: '$', suffix: '' }"
            />
          </b-form-group>
          <b-form-group label="To" label-for="toClassTuition" label-class="form-label" class="col-md-6">
            <currency-input
              id="toClassTuition"
              v-model="toClassTuition"
              class="form-control"
              placeholder="Enter tuition fee range"
              :allow-negative="false"
              :currency="{ prefix: '$', suffix: '' }"
            />
          </b-form-group>
        </div>
      </b-form-group>

      <b-form-group label="Pass Rate" label-for="passRatio" label-class="form-label">
        <currency-input
          id="passRatio"
          v-model="passRatio"
          class="form-control"
          placeholder="Enter pass percentage"
          :allow-negative="false"
          :value-range="{ min: 0, max: 100 }"
          :currency="{ prefix: '', suffix: '%' }"
        />
      </b-form-group>

      <b-form-group label="Average Program Weeks" label-for="avgProgramWeeks" label-class="form-label">
        <b-form-input
          id="avgProgramWeeks"
          v-model="avgProgramWeeks"
          class="form-control"
          placeholder="Enter average program weeks"
          type="number"
          :allow-negative="false"
        />
      </b-form-group>

      <b-form-group label="School Offerings" label-for="bestSchoolOfferings" label-class="form-label">
        <v-select
          id="bestSchoolOfferings"
          class="form-control v-select-custom"
          label="text"
          v-model="bestSchoolOfferings"
          :reduce="item => item.value"
          placeholder="Select school's offerings"
          :options="BestSchoolOfferingsOptions"
          multiple
        >
        </v-select>

        <b-form-input
          id="other_offerings"
          class="mt-2"
          placeholder="Please specify."
          v-model="otherOfferings"
          required
          v-if="bestSchoolOfferings.includes('other')"
        />
      </b-form-group>

      <b-form-group label="Short Overview/Description" label-for="short_review" label-class="form-label">
        <!-- <b-form-input id="short_review" placeholder="Please Enter Review" v-model="shortReview" /> -->
        <b-form-textarea
          id="short_sch_desc"
          placeholder="Enter school's short/summarized description."
          :rows="5"
          v-model="shortDescription"
        />
      </b-form-group>

      <b-form-group label="Overview/Description" label-for="bio" label-class="form-label">
        <editor
          :init="{
            height: 400,
            menubar: 'edit view insert format tools table help',
            plugins: [
              'advlist autolink lists link image charmap print preview anchor',
              'searchreplace visualblocks code fullscreen',
              'insertdatetime media table paste code help wordcount'
            ],
            toolbar:
              'undo redo | formatselect | bold italic backcolor | \
           alignleft aligncenter alignright alignjustify | \
           bullist numlist outdent indent | removeformat | help'
          }"
          v-model="bestSchoolDesc"
          placeholder="Enter school's overview/description"
        />
      </b-form-group>

      <div class="row">
        <b-form-group class="col-md-4">
          <b-form-checkbox id="checkbox-1" v-model="enrollBtn" name="checkbox-1" switch>
            Enrollment Button Active
          </b-form-checkbox>
        </b-form-group>
        <b-form-group class="col-md-4">
          <b-form-checkbox id="checkbox-2" v-model="websiteBtn" name="checkbox-2" switch>
            Website Button Active
          </b-form-checkbox>
        </b-form-group>
        <b-form-group class="col-md-4">
          <b-form-checkbox id="checkbox-5" v-model="callLogBtn" name="checkbox-5" switch>
            Show Call Logs on CNA
          </b-form-checkbox>
        </b-form-group>
      </div>

      <page-separator title="Add upcoming Classes" v-if="!enrollLink" />

      <div v-if="!enrollLink">
        <b-btn @click.prevent="openUpcomingClassesModal()" variant="" style="width: 80px" class="upload-button">
          <span>Add </span>
        </b-btn>
      </div>

      <!-- Photo Gallery -->
      <page-separator title="Photo Gallery Upload" />
      <b-form-group label="" label-for="review" label-class="form-label">
        <image-uploader @image="upload" :uploadBtnOnly="true" :isPictureLoading="isPictureLoading" />

        <div class="d-flex flex-wrap mt-2">
          <div
            style="position: relative"
            class="mr-2 mb-2"
            v-for="(photo, index) of photo_gallery"
            :key="`photo-${index}`"
          >
            <b-img
              :src="photo"
              class="img-fluid"
              style="
                height: 6rem;
                width: 7rem;
                color: white;
                border-radius: 0.5rem;
                border: solid gray 0.1px !important;
                object-fit: cover;
                min-width: 5rem;
              "
              alt="Logo"
            />
            <!-- <b-button color="transparent" class="float-circle-btn" > -->
            <a
              class="clickable-item"
              @click.prevent="removeVisual(index)"
              style="color: #214163; position: absolute; top: 0; right: 2px"
              ><i class="fas fa-times-circle"></i
            ></a>
            <!-- </b-button> -->
          </div>
        </div>
      </b-form-group>

      <b-btn variant="primary" :disabled="isLoading" style="width: 150px" type="submit" class="btn-normal">
        <i v-if="isLoading" class="fas fa-circle-notch fa-spin"></i>
        <span v-else>Update</span>
      </b-btn>
    </b-form>
    <add-upcoming-classes-modal
      :show-modal="showAddUpcomingClassesModal"
      @close="hideUpcomingClassesModal"
      :reqDemo="reqDemo"
    />
  </div>
</template>

<script>
import axios from 'axios';
import { mapActions } from 'vuex';
import PageHeader from '@/components/Ui/PageHeader.vue';
import Page from '@/components/Page.vue';
import Editor from '@tinymce/tinymce-vue';
import AddUpcomingClassesModal from './AddUpcomingClassesModal.vue';
import { SCHOOL_OFFERINGS } from '@/common/constants';
import ImageUploader from '@/components/ImageUploader.vue';
import { FmvAvatar } from 'fmv-avatar';
import PageSeparator from '@/components/Ui/PageSeparator.vue';

export default {
  components: { PageHeader, Editor, AddUpcomingClassesModal, ImageUploader, FmvAvatar, PageSeparator },
  extends: Page,
  data() {
    return {
      title: 'Partnership Profile',
      tinemceApiKey: process.env.VUE_APP_TINEMCE_API_KEY,

      bestSchoolOfferings: [],
      otherOfferings: null,
      fromClassTuition: null,
      toClassTuition: null,
      enrollBtn: true,
      websiteBtn: true,
      callLogBtn:false,
      bestSchoolImgUrl: null,
      passRatio: null,
      bestSchoolDesc: null,
      avgProgramWeeks: null,
      shortDescription: null,
      photo_gallery: [],
      BestSchoolOfferingsOptions: SCHOOL_OFFERINGS,
      uploadFile: {
        BestSchoolImg: null,
        photoGalleryImg: null
      },
      uploadPercent: { photoGalleryImg: 0 },
      uploadCancelTokenSource: { photoGalleryImg: null },
      isLogoUploading: false,
      isPictureLoading: false,
      isBestSchoolImgUploading: false,
      isLoading: false,
      showAddUpcomingClassesModal: false,
      reqDemo: false,
      enrollLink: null
    };
  },
  computed: {},
  methods: {
    ...mapActions('school', ['getSchoolImageUploadPresignedUrl', 'updateBestSchoolInfo', 'getBestSchoolInfo']),
    ...mapActions('s3Upload', ['uploadToPresignedUrl']),
    setImage(file, fileType) {
      this.uploadFile[fileType] = file;
      this.uploadImage(fileType);
    },
    removeVisual(index) {
      this.photo_gallery?.splice(index, 1);
    },
    upload(file) {
      // const target = e.target;
      // if (!target.files || !target.files.length) return;
      this.uploadFile['photoGalleryImg'] = file;
      this.uploadImage('photoGalleryImg');
    },
    async onSubmit() {
      this.isLoading = true;
      try {
        const data = {
          best_sch_img: this.bestSchoolImgUrl,
          best_offering: this.bestSchoolOfferings,
          class_tuition_to: this.toClassTuition,
          class_tuition_from: this.fromClassTuition,
          enroll_btn_active: this.enrollBtn,
          website_btn_active: this.websiteBtn,
          show_ai_call_logs_trenroll:this.callLogBtn,
          other_best_offering: this.otherOfferings,
          pass_rate: this.passRatio,
          avg_program_weeks: this.avgProgramWeeks,
          best_school_desc: this.bestSchoolDesc,
          short_sch_review: this.shortDescription,
          photo_gallery: this.photo_gallery
        };

        await this.updateBestSchoolInfo(data);

        this.makeToast({ variant: 'success', msg: 'Details Updated' });
      } catch (error) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
      this.isLoading = false;
    },
    async uploadImage(uploadType) {
      this.uploadCancelTokenSource[uploadType] = axios.CancelToken.source();

      this.isBestSchoolImgUploading = uploadType === 'BestSchoolImg';
      this.isPictureLoading = uploadType === 'photoGalleryImg';
      try {
        const urlResp = await this.getSchoolImageUploadPresignedUrl({
          file_name: this.uploadFile[uploadType].name,
          content_type: this.uploadFile[uploadType].type
        });
        await this.uploadToPresignedUrl({
          url: urlResp.upload_url,
          file: this.uploadFile[uploadType],
          config: {
            onUploadProgress: function(progressEvent) {
              this.uploadPercent[uploadType] = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            }.bind(this),
            cancelToken: this.uploadCancelTokenSource[uploadType].token
          }
        });
        if (uploadType === 'BestSchoolImg') {
          this.bestSchoolImgUrl = urlResp.upload_url.split('?')[0];
        } else if (uploadType === 'photoGalleryImg') {
          this.photo_gallery.push(urlResp.upload_url.split('?')[0]);
        }
      } catch (error) {
        this.uploadFile[uploadType] = null;
      }

      this.uploadCancelTokenSource[uploadType] = null;
      this.uploadPercent[uploadType] = 0;

      if (uploadType === 'BestSchoolImg') this.isBestSchoolImgUploading = false;
      else if (uploadType === 'photoGalleryImg') this.isPictureLoading = false;
    },

    openUpcomingClassesModal() {
      this.showAddUpcomingClassesModal = true;
    },
    hideUpcomingClassesModal() {
      this.showAddUpcomingClassesModal = false;
      this.fetchSchoolDemoReq();
    },

    async fetchSchoolDemoReq() {
      try {
        const res = await this.getBestSchoolInfo();
        this.reqDemo = res.data.extra.demo_request;
      } catch (error) {
        //
      }
    }
  },
  async mounted() {
    try {
      const res = await this.getBestSchoolInfo();

      this.bestSchoolImgUrl = res.data.best_sch_img;
      this.bestSchoolOfferings = res.data.best_offering;
      this.toClassTuition = res.data.class_tuition_to;
      this.fromClassTuition = res.data.class_tuition_from;
      this.enrollBtn = res.data.enroll_btn_active;
      this.websiteBtn = res.data.website_btn_active;
      this.callLogBtn=res.show_ai_call_logs_trenroll;
      this.otherOfferings = res.data.other_best_offering;
      this.passRatio = res.data.pass_rate;
      this.avgProgramWeeks = res.data.avg_program_weeks;
      this.bestSchoolDesc = res.data.best_school_desc;
      this.shortDescription = res.data.short_sch_review;
      this.photo_gallery = res.data.photo_gallery;
      this.reqDemo = res.data.extra.demo_request;
      this.enrollLink = res.data.enrollment_link;
    } catch (error) {
      this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
    }
  }
};
</script>

<style></style>
