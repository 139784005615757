<template>
  <b-modal ref="uniPreviewModal" hide-footer title="Preview as Student" centered lazy @hide="hideModal" size="xl">
    <div style="height: calc(100vh - 150px)">
      <iframe :src="schoolUrl" height="100%" width="100%" title="Iframe Example"></iframe>
    </div>
  </b-modal>
</template>

<script>
import { mapGetters } from 'vuex';
import { get } from 'lodash';

export default {
  name: 'UniversityPreviewModal',

  props: {
    showModal: { type: Boolean, default: false },
    university: { type: Object, default: () => {} },
    highSchool: { type: Object, default: () => {} },
    org: { type: Object, default: () => {} },
  },

  data() {
    return {
      schoolUrl: '',
    };
  },
  validations() {
    return {};
  },
  computed: {
    ...mapGetters('school', ['getCurrentSchool']),
  },
  methods: {
    get,

    async hideModal() {
      this.schoolUrl = '';
      this.$emit('close');
    },

    hideModalManual() {
      this.$refs.uniPreviewModal.hide();
      this.hideModal();
    },
  },
  watch: {
    showModal(value) {
      if (value) {
        if (this.university) {
          this.schoolUrl = `${process.env.VUE_APP_UNI_SPONSORED_URL}/${this.university.slug}/${this.university.offered_programs[0].slug}/programs-near-me/${this.getCurrentSchool.slug}/?preview=1`;
        }
        if (this.highSchool) {
          this.schoolUrl = `${process.env.VUE_APP_SPONSORED_TRAINING_URL}/${this.highSchool.state.slug}/${this.highSchool.slug}/${this.highSchool.program.slug}/training-near-me/${this.getCurrentSchool.slug}/?preview=1`;
        }
        if (this.org) {
          this.schoolUrl = `${this.org.organization_site.wbst_domain}training-providers/${this.getCurrentSchool.slug}/?preview=1`;
        }
        this.$refs.uniPreviewModal.show();
      }
    },
  },
  mounted() {},
};
</script>

<style>
</style>