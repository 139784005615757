<template>
  <div>
    <page-header
      :title="
        get(this.getLoggedInUser, 'role_type') === USER_ROLE_TYPES.SCHOOL ? 'Partnered Employers' : 'Partnerships'
      "
      :info-text="
        get(this.getLoggedInUser, 'role_type') === USER_ROLE_TYPES.EMPLOYER
          ? 'Manage partnerships with schools.'
          : 'Browse partnered employers and their profiles.'
      "
      :container-class="containerClass"
    />
    <div class="page-section">
      <div :class="containerClass">
        <div
          class="card bg-primary p-2"
          style="color: white"
          v-if="get(getLoggedInUser, 'role') === USER_ROLES.SCHOOL_OWNER"
        >
          <div class="d-flex justify-content-center align-items-center p-2" style="font-size: 16px">
            <strong> Invite Employer Partners </strong>
          </div>

          <div class="p-2 mb-4">
            Use your invite link below to invite employers to request to partner with your Student Center. Accepted
            partnership requests will give employers the ability to view your students, offer sponsorships, and post
            jobs!
          </div>
          <b-alert variant="soft-light px-12pt" show>
            <div class="d-flex align-items-center justify-content-between">
              <span class="text-black-100"
                ><strong>{{ inviteURL }}</strong></span
              >

              <span v-if="isDomainCopied" class="text-success"><md-icon>check</md-icon> Copied</span>
              <a href="#" @click.prevent="copyDomain" v-else v-b-popover.hover.top="'Copy Invite Link'">
                <md-icon>content_copy</md-icon>
              </a>
            </div>
          </b-alert>
        </div>

        <div class="card mb-0">
          <div class="card-header">
            <div class="row align-items-center" style="white-space: nowrap">
              <!-- Search -->
              <div class="col-lg-auto">
                <form class="search-form search-form--light d-lg-inline-flex mb-8pt mb-lg-0" @submit.prevent="">
                  <b-form-input
                    class="w-lg-auto"
                    :placeholder="'Search Partners'"
                    v-model="searchTerm"
                    @input="onSearch"
                  />
                  <b-btn variant="flush" type="submit">
                    <md-icon>search</md-icon>
                  </b-btn>
                </form>
              </div>

              <!-- Filters and Add Btn -->
              <div class="col-lg d-flex flex-wrap justify-content-end">
                <div class="col-lg-6">
                  <v-select
                    id="program"
                    class="form-control v-select-custom"
                    label="text"
                    v-model="filter"
                    :reduce="(filter) => filter.value"
                    placeholder="Select Status"
                    :options="partnershipStatuses"
                    @input="fetchPartnerships()"
                  >
                    <template #search="{ attributes, events }">
                      <input class="vs__search" v-bind="attributes" v-on="events" />
                    </template>
                  </v-select>
                </div>
              </div>
            </div>
          </div>

          <!-- Schools Table -->
          <b-table
            :fields="tableColumns"
            :items="partnerships"
            :busy="isLoading"
            head-variant="light"
            class="table-nowrap"
            hover
            responsive
            no-local-sorting
            @sort-changed="onSortChange"
            @row-clicked="showProfile"
          >
            <template #cell(logo_url)="data">
              <user-avatar
                slot="aside"
                size="md"
                rounded="false"
                :user="data.item"
                :fileUrl="
                  getLoggedInUser.role_type === USER_ROLE_TYPES.SCHOOL
                    ? get(data.item, 'request_from.role') === 'school'
                      ? get(data.item, 'request_to.logo_url')
                      : get(data.item, 'request_from.logo_url')
                    : get(data.item, 'request_from.role') === 'employer'
                    ? get(data.item, 'request_to.logo_url')
                    : get(data.item, 'request_from.logo_url')
                "
              >
              </user-avatar>
            </template>

            <template #cell(name)="data">
              {{
                getLoggedInUser.role_type === USER_ROLE_TYPES.SCHOOL
                  ? get(data.item, 'request_from.role') === 'school'
                    ? get(data.item, 'request_to.name')
                    : get(data.item, 'request_from.name')
                  : get(data.item, 'request_from.role') === 'employer'
                  ? get(data.item, 'request_to.name')
                  : get(data.item, 'request_from.name')
              }}
            </template>

            <template #cell(type)="data">
              {{ $t(`partnershipTypes.${data.item.type}`) }}
            </template>

            <template #cell(unlocked_students)="data">
              <span class="d-flex justify-content-center">
                <a
                  href="#"
                  @click.prevent="openStdModal(data.item)"
                  class="text-primary"
                  v-b-popover.hover.right
                  title="View Students"
                  >{{ data.item.unlocked_students }} Student(s)</a
                >
              </span>
            </template>

            <template #cell(programs)="data">
              <a href="#" @click.prevent class="text-primary" :id="`program-popover-${data.item.id}`"
                >{{ data.item.programs.length }} Program(s)</a
              >
              <programs-list-popover :programs="data.item.programs" :target-id="data.item.id" />
            </template>

            <template #cell(partner_recurring_fee)="data"> ${{ $n(data.item.partner_recurring_fee) }} </template>

            <template #cell(partner_next_cycle)="data">
              {{
                !data.item.partner_revoke_at && data.item.partner_next_cycle
                  ? formatFullDate(data.item.partner_next_cycle)
                  : ''
              }}
            </template>

            <template #cell(status)="data">
              <b-badge
                :class="`border border-${
                  data.item.partner_revoke_at ? 'danger' : data.item.status === 'requested' ? 'secondary' : 'success'
                } text-${
                  data.item.partner_revoke_at ? 'danger' : data.item.status === 'requested' ? 'secondary' : 'success'
                }`"
                variant="none"
                >{{
                  data.item.partner_revoke_at ? 'To be dissolved' : $t(`partnershipStatusTypes.${data.item.status}`)
                }}</b-badge
              >
            </template>
            <template #cell(to_be_freed)="data">
              <b-badge
                :class="`border border-${data.item.to_be_freed ? 'primary' : ''} text-${
                  data.item.to_be_freed ? 'primary' : ''
                }`"
                variant="none"
                v-b-popover.hover.left
                :title="
                  data.item.to_be_freed
                    ? 'This partnership will be freed in next cycle, because this employer now belongs to partnered organization'
                    : ''
                "
                >{{ data.item.to_be_freed ? 'To be Freed' : '' }}</b-badge
              >
            </template>

            <template #head(actions)="">
              <span></span>
            </template>
            <template #cell(actions)="data">
              <div v-if="data.item.status !== 'requested'">
                <a
                  href="#"
                  @click.prevent="downloadPdf(data.item)"
                  v-b-popover.hover.left
                  title="Download Agreement"
                  v-if="data.item.agreement_url"
                  class="mr-2"
                >
                  <md-icon class="text-secondary">file_download </md-icon>
                </a>
                <a
                  v-if="
                    data.item.partner_revoke_at && get(getLoggedInUser, 'role_type') === USER_ROLE_TYPES.EMPLOYER_ADMIN
                  "
                  href="#"
                  @click.prevent="reactivatePartnership(data.item.id)"
                  v-b-popover.hover.left
                  :title="$t('partnershipMsgs.reactivate')"
                >
                  <md-icon class="text-primary">check_circle_outline</md-icon>
                </a>
                <a
                  v-else-if="!data.item.partner_revoke_at"
                  href="#"
                  @click.prevent="revokePartnership(data.item.id)"
                  v-b-popover.hover.left
                  :title="$t('partnershipMsgs.revoke')"
                >
                  <md-icon class="text-danger">highlight_off</md-icon>
                </a>
              </div>
            </template>
          </b-table>

          <!-- Footer Pagination -->
          <div class="card-footer">
            <pagination
              v-model="currentPage"
              :total-rows="totalPartnerships"
              :per-page="perPage"
              @change="onPageChange"
              aria-controls="partnerships-table"
            />
          </div>
        </div>
      </div>
    </div>
    <unlocked-std-modal :showModal="showStdModal" :item="item" @close="hideRegisterModal" />
  </div>
</template>

<script>
import PageHeader from '@/components/Ui/PageHeader.vue';
import { debounce, get } from 'lodash';
import { mapActions, mapGetters } from 'vuex';

import Page from '@/components/Page.vue';
import Pagination from '@/components/Ui/Pagination.vue';
import MdIcon from '../../components/Ui/MdIcon.vue';
import { DEFAULT_PAGE_SIZE, USER_ROLE_TYPES, USER_ROLES } from '../../common/constants';
import { formatDateTime, formatFullDate } from '../../common/utils';
import ProgramsListPopover from './PartneredPrograms/ProgramsListPopover.vue';
import UserAvatar from '../../components/User/UserAvatar.vue';
import UnlockedStdModal from './UnlockStudents/UnlockedStdModal.vue';
export default {
  components: { MdIcon, PageHeader, Pagination, UserAvatar, ProgramsListPopover, UnlockedStdModal },
  extends: Page,

  name: 'PartnershipList',

  data() {
    return {
      title: '',
      isDomainCopied: false,
      windowWidth: window.innerWidth,
      isLoading: false,
      isResendingAll: false,

      perPage: DEFAULT_PAGE_SIZE,
      currentPage: 1,

      totalPartnerships: 0,
      partnerships: [],

      searchTerm: '',
      USER_ROLE_TYPES,
      isDownloading: false,
      stdCount: {},
      showStdModal: false,
      item: null,
      USER_ROLES,
      filter: null,
      partnershipStatuses: [
        { text: 'Accepted', value: 'request_accepted' },
        { text: 'Pending', value: 'requested' },
        { text: 'To be Dissolved', value: 'revoked' },
      ],
    };
  },
  watch: {
    getLoggedInUser: {
      immediate: true,
      deep: true,
      handler(value) {
        this.title = get(value, 'role_type') === USER_ROLE_TYPES.SCHOOL ? 'Partnered Employers' : 'Partnerships';
      },
    },
  },
  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),
    ...mapGetters('school', ['getCurrentSchool']),
    breadcrumb() {
      return [
        { text: 'Home', to: this.routes.home },
        { text: 'Manage Partnerships', active: true },
      ];
    },

    inviteURL() {
      return `${this.getClickableLink(process.env.VUE_APP_MAIN_DOMAIN)}/employer-signup/?referral_id=${get(
        this.getLoggedInUser,
        'linked_entity.referral_id'
      )}`;
    },
    isMobileScreen() {
      return this.windowWidth <= 776;
    },
    tableColumns() {
      return [
        { key: 'logo_url', label: '' },
        { key: 'name', label: 'Name', tdClass: 'clickable-item' },
        { key: 'type', label: 'Partnership Type' },
        {
          key: 'accepted_at',
          label: 'Partners since',
          tdClass: 'clickable-item',
          formatter: (value) => (value ? formatDateTime(value) : value),
        },
        { key: 'to_be_freed', label: 'Payment Status', tdClass: 'clickable-item' },

        ...(get(this.getLoggedInUser, 'role') === USER_ROLES.SCHOOL_OWNER ||
        get(this.getLoggedInUser, 'role') === USER_ROLES.EMPLOYER_ADMIN
          ? [{ key: 'partner_recurring_fee', label: 'Recurring Fee', tdClass: 'clickable-item' }]
          : []),

        ...(get(this.getLoggedInUser, 'role') === USER_ROLES.SCHOOL_OWNER ||
        get(this.getLoggedInUser, 'role') === USER_ROLES.EMPLOYER_ADMIN
          ? [
              {
                key: 'partner_next_cycle',
                label: 'Next Payment Date',
                tdClass: 'clickable-item',
                formatter: (value) => (value ? formatFullDate(value) : value),
              },
            ]
          : []),

        { key: 'status', label: 'Status', tdClass: 'clickable-item' },
        { key: 'programs', label: 'Programs', tdClass: 'clickable-item' },
        ...(get(this.getLoggedInUser, 'role_type') === USER_ROLE_TYPES.SCHOOL
          ? [
              {
                key: 'unlocked_students',
                label: 'Unlocked Students',
                tdClass: 'text-right ',
              },
            ]
          : []),
        ...(get(this.getLoggedInUser, 'role') === USER_ROLES.SCHOOL_OWNER ||
        get(this.getLoggedInUser, 'role') === USER_ROLES.EMPLOYER_ADMIN
          ? [
              {
                key: 'actions',
                tdClass: 'text-right ',
                thStyle: { minWidth: '100px' },
              },
            ]
          : []),
      ];
    },
  },

  methods: {
    ...mapActions('partnership', ['deletePartnership', 'unrevokePartnership', 'getAllPartnerships']),
    ...mapActions('fileDownload', ['downloadFile']),
    ...mapActions('school', ['getPartneredSchools']),
    ...mapActions('employer', ['getPartneredEmployers']),
    formatDateTime,
    formatFullDate,
    get,
    openStdModal(item) {
      this.showStdModal = true;
      this.item = item;
    },

    hideRegisterModal() {
      this.showStdModal = false;
      this.item = null;
    },
    async copyDomain() {
      try {
        await navigator.clipboard.writeText(this.inviteURL);
        this.isDomainCopied = true;

        setTimeout(() => (this.isDomainCopied = false), 2000);
      } catch ($e) {
        this.makeToast({ variant: 'danger', msg: 'Unable to copy.' });
      }
    },
    async downloadPdf(data) {
      this.isDownloading = true;
      try {
        const h = this.$createElement;
        this.makeToast({
          variant: 'primary',
          msg: h('span', [h('i', { class: 'fas fa-download mr-2' }), 'Downloading File']),
          toaster: 'b-toaster-bottom-left',
        });

        await this.downloadFile({ fileUrl: data.agreement_url, removeTimestamp: true });

        this.makeToast({
          variant: 'success',
          msg: h('span', [h('i', { class: 'fas fa-download mr-2' }), 'File Downloaded']),
          toaster: 'b-toaster-bottom-left',
        });
      } catch (err) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
      this.isDownloading = false;
    },
    getClickableLink(link) {
      return link.startsWith('http://') || link.startsWith('https://')
        ? link
        : `${process.env.VUE_APP_DOMAIN_PREFIX || 'https'}://${link}`;
    },
    showProfile(data) {
      if (
        data.status !== 'requested' &&
        !get(this.getLoggedInUser, 'linked_entity.organization.show_all_school') &&
        this.getLoggedInUser.role_type === USER_ROLE_TYPES.EMPLOYER
      ) {
        this.$router.push({
          name:
            this.getLoggedInUser.role_type === USER_ROLE_TYPES.SCHOOL ? 'schl-employer-profile' : 'emp-school-profile',
          params: {
            id:
              this.getLoggedInUser.role_type === USER_ROLE_TYPES.SCHOOL
                ? get(data, 'request_from.role') === 'school'
                  ? get(data, 'request_to.id')
                  : get(data, 'request_from.id')
                : get(data, 'request_from.role') === 'employer'
                ? get(data, 'request_to.id')
                : get(data, 'request_from.id'),
          },
        });
      }
    },
    async reactivatePartnership(id) {
      try {
        const isConfirmed = await this.$bvModal.msgBoxConfirm(
          'Please ensure that you want to reactivate the partnership. You will be able to access all of the partnership benefits after reactivation.',
          {
            title: 'Reactivate Partnership',
            size: 'md',
            buttonSize: 'sm',
            okVariant: 'success',
            okTitle: 'Yes',
            cancelTitle: 'No',
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true,
          }
        );
        if (isConfirmed) {
          await this.unrevokePartnership(id);
          this.fetchPartnerships();
          this.makeToast({ variant: 'success', msg: 'Partnership Reactivated!' });
        }
      } catch (error) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
    },
    async revokePartnership(id) {
      try {
        const isConfirmed = await this.$bvModal.msgBoxConfirm(
          'Please ensure that you want to revoke the partnership. You will not be able to access any of the partnership benefits after dissolution. Note: Partnership will be dissolved when current cycle ends.',
          {
            title: 'Revoke Partnership',
            size: 'md',
            buttonSize: 'sm',
            okVariant: 'danger',
            okTitle: 'Yes',
            cancelTitle: 'No',
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true,
          }
        );
        if (isConfirmed) {
          await this.deletePartnership(id);
          this.fetchPartnerships();
          this.makeToast({ variant: 'success', msg: 'Partnership marked for dissolution!' });
        }
      } catch (error) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
    },
    async fetchPartnerships(pageNum = 1, params = {}) {
      this.isLoading = true;

      const response = await this.getAllPartnerships({
        limit: this.perPage,
        offset: (pageNum - 1) * this.perPage,
        ...(this.ordering && { ordering: this.ordering }),
        ...(this.searchTerm && { search: this.searchTerm }),
        ...(this.filter && { partnership_status: this.filter }),
        ...params,
      });

      this.partnerships = response.data.results;
      this.currentPage = pageNum;
      this.totalPartnerships = response.data.count;
      this.isLoading = false;
    },

    onPageChange(pageNum) {
      this.fetchPartnerships(pageNum);
    },

    onSortChange(context) {
      this.ordering = context.sortDesc ? '-' + context.sortBy : context.sortBy;
      this.fetchPartnerships();
    },
    handleResize() {
      this.windowWidth = window.innerWidth;
    },
    onSearch() {
      this.debouncedSearchPartnerhsips(this);
    },

    debouncedSearchPartnerhsips: debounce((vm) => {
      vm.fetchPartnerships();
    }, 500),
  },

  async mounted() {
    this.fetchPartnerships();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
};
</script>
