<template>
  <div :class="{ 'card--raised': raised }" class="card card-group-row__card text-center o-hidden">
    <span
      v-if="ribbon"
      class="corner-ribbon corner-ribbon--default-left-top corner-ribbon--shadow bg-primary text-white"
      v-text="ribbon"
    />
    <div class="card-body d-flex flex-column">
      <div class="flex-grow-1 mb-16pt">
        <span :class="iconClass" class="w-64 h-64 icon-holder rounded-circle d-inline-flex mb-16pt">
          <!-- <md-icon v-text="icon" /> -->
          <i class="fas fa-coins fa-lg"></i>
        </span>
        <h4 class="mb-8pt">{{ $t(title) }}</h4>
        <p class="text-black-70">{{ $t(description) }}</p>
      </div>
      <p class="d-flex justify-content-center align-items-center">
        <span class="h3 m-0 font-weight-normal">{{ tokens }}</span>
        <span class="h4 m-0 font-weight-normal">TT</span>
      </p>

      <p class="d-flex justify-content-center align-items-start">
        <span class="m-0 font-weight-normal" style="font-size: 1rem"
          ><md-icon class="text-success">check</md-icon> {{ jobCredits }} job posting(s)</span
        >
      </p>

      <!-- <p class="d-flex justify-content-center align-items-center m-0">
        <span class="h4 m-0 font-weight-normal">&dollar;</span>
        <span class="h2 m-0 font-weight-normal">{{ price }}</span>
      </p> -->
    </div>
    <div class="card-footer">
      <b-btn
        :variant="localActionColor"
        :disabled="actionDisabled"
        @click.prevent="handleAction"
        class="btn-normal"
        style="width: 100px"
        v-b-popover.hover.bottom="'Purchase Now'"
        ><i class="fas fa-circle-notch fa-spin" v-if="isLoading" /><span v-else>$ {{ price }}</span></b-btn
      >
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    title: { type: String, default: null },
    description: { type: String, default: null },
    price: { type: Number, default: null },
    tokens: { type: Number, default: null },
    color: { type: String, default: 'accent' },
    icon: { type: String, default: null },
    iconColor: { type: String, default: null },
    raised: { type: Boolean, default: false },
    ribbon: { type: String, default: null },
    action: { type: String, default: 'Purchase' },
    actionColor: { type: String, default: null },
    actionDisabled: { type: Boolean, default: false },
    isLoading: { type: Boolean, default: false },
    package: { type: Object },
    jobCredits: { type: Number, default: null },
  },
  computed: {
    localIconColor() {
      return this.iconColor || `outline-${this.color}`;
    },
    localActionColor() {
      return this.actionColor || this.color;
    },
    iconClass() {
      return `icon-holder--${this.localIconColor}`;
    },
  },

  methods: {
    handleAction() {
      this.$emit('onAction', this.package);
    },
  },
};
</script>
