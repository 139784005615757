<template>
  <div :class="containerClass">
    <page-header
      v-if="this.getLoggedInUser.role_type === USER_ROLE_TYPES.STUDENT"
      title="Manage Billing"
      :container-class="null"
      class="mb-32pt"
    />
    <page-header
      v-else
      title="Manage Billing"
      :container-class="null"
      class="mb-32pt"
      button-label="Payment History"
      :button-path="{ name: 'manage-payment-history' }"
      button-variant="secondary"
      :info-text="
        this.getLoggedInUser.role_type === USER_ROLE_TYPES.EMPLOYER
          ? 'Save your payment method to make hires, post jobs and unlock a candidate.'
          : ''
      "
    />

    <page-separator title="" />
    <b-skeleton-wrapper :loading="isLoading">
      <template #loading>
        <div class="d-flex justify-content-center">
          <div class="card card-block card-stretch card-height col-md-12">
            <div class="card-body text-center">
              <i class="fas fa-credit-card fa-lg text-muted mt-4 mb-4" style="font-size: 3rem" />

              <p><b-skeleton width="60%"></b-skeleton></p>
              <p><b-skeleton width="90%"></b-skeleton></p>
              <p><b-skeleton width="75%"></b-skeleton></p>
              <div class="d-flex justify-content-center">
                <b-skeleton type="button" width="250px"></b-skeleton>
              </div>
            </div>
          </div>
        </div>
      </template>

      <div class="d-flex justify-content-center" v-if="!paymentMethod">
        <div class="card card-block card-stretch card-height col-md-12">
          <div class="card-body text-center">
            <i class="fas fa-credit-card fa-lg text-muted mt-4 mb-4" style="font-size: 3rem" />
            <h4>No Payment Method Saved</h4>
            <p class="text-muted">No payment method added. Click below to configure default payment method.</p>
            <b-btn
              variant="primary"
              style="width: 250px"
              @click.prevent="initiateSavePaymentMethod"
              class="btn-normal"
              :disabled="isSaving || isLoading"
            >
              <i v-if="isSaving" class="fas fa-circle-notch fa-spin"></i>
              <span v-else>Add Payment Method</span>
            </b-btn>
          </div>
        </div>
      </div>

      <div class="d-flex justify-content-center" v-else>
        <div class="card card-block card-stretch card-height col-md-12">
          <div class="card-body">
            <h5>Primary</h5>
            <p class="mb-1">Your primary billing method is used for all recurring payments.</p>

            <page-separator title="" />
            <div class="d-flex align-items-center">
              <i
                :class="
                  CARD_ICONS[get(paymentMethod, 'card_brand')]
                    ? `fab fa-cc-${CARD_ICONS[get(paymentMethod, 'card_brand')]} icon-12pt mr-2`
                    : `fas fa-credit-card icon-12pt mr-2`
                "
                style="font-size: 1.5rem"
              ></i>
              <span class="flex" style="white-space: nowrap">
                Your current payment method is
                <strong
                  >{{ $t(`paymentCardBrands.${get(paymentMethod, 'card_brand', 'unknown')}`) }} ending with
                  {{ get(paymentMethod, 'last_four') }}</strong
                >.
              </span>
            </div>
            <p class="text-muted mt-1">
              Note: To change the default payment method, remove the existing method and add a new one.
            </p>

            <div class="d-flex justify-content-end">
              <b-btn
                variant="danger"
                @click.prevent="removePaymentMethod"
                class="btn-normal"
                :disabled="isRemoving"
                style="width: 120px"
              >
                <i v-if="isRemoving" class="fas fa-circle-notch fa-spin"></i>
                <span v-else>Remove</span>
              </b-btn>
            </div>
          </div>
        </div>
      </div>
    </b-skeleton-wrapper>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import PageSeparator from '@/components/Ui/PageSeparator.vue';
import PageHeader from '../../components/Ui/PageHeader.vue';
import { CARD_ICONS, USER_ROLE_TYPES } from '../../common/constants';
import Page from '../../components/Page.vue';
import { get } from 'lodash';

export default {
  components: {
    PageSeparator,
    PageHeader,
  },
  extends: Page,

  data() {
    return {
      title: 'Payment Method',
      paymentMethod: null,
      isLoading: false,
      isSaving: false,
      isRemoving: false,
      CARD_ICONS,
      USER_ROLE_TYPES,
    };
  },

  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),
    breadcrumb() {
      return [
        { text: this.$t('home'), to: this.routes.home },
        { text: 'Billing', to: { name: 'manage-billing' } },
        { text: 'Manage Payment Method', active: true },
      ];
    },
  },
  methods: {
    ...mapActions('payments', [
      'savePaymentMethod',
      'savePaymentMethodSuccess',
      'getDefaultPaymentMethod',
      'removeDefaultPaymentMethod',
      'verifyPaymentInfo',
    ]),
    get,

    async removePaymentMethod() {
      this.isRemoving = true;
      const isConfirmed = await this.$bvModal.msgBoxConfirm(
        `Please confirm that you want to remove your default payment method. 
        ${
          this.getLoggedInUser.role_type === USER_ROLE_TYPES.STUDENT
            ? ''
            : 'All your active subscriptions and partnerships will be removed unless you add a new method.'
        }
        `,
        {
          title: 'Remove Payment Method',
          size: 'md',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'Yes',
          cancelTitle: 'No',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true,
        }
      );
      if (isConfirmed) {
        try {
          await this.removeDefaultPaymentMethod();
          this.paymentMethod = null;
          this.makeToast({ variant: 'success', msg: 'Payment Method Removed!' });
        } catch (_err) {
          this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
        }
      }
      this.isRemoving = false;
    },

    async fetchPaymentMethod() {
      this.isLoading = true;
      try {
        this.paymentMethod = get((await this.getDefaultPaymentMethod()).data, 'data');
      } catch (err) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
      this.isLoading = false;
    },

    async initiateSavePaymentMethod() {
      this.isSaving = true;
      try {
        const resp = (await this.savePaymentMethod()).data;
        window.location.href = resp.data;
      } catch (err) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
        this.isSaving = false;
      }
    },
  },

  async mounted() {
    this.isLoading = true;
    if (this.$route.query.saveSuccess) {
      const stripeSessionId = this.$route.query.stripeSessionId;
      try {
        this.paymentMethod = (await this.savePaymentMethodSuccess({ session_id: stripeSessionId })).data.data;
        this.makeToast({ variant: 'success', msg: 'Payment method saved' });
      } catch (err) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
        this.fetchPaymentMethod();
      }
      this.$router.push({ name: this.$route.name });
      this.isLoading = false;
    } else if (this.$route.query.saveCancel) {
      this.makeToast({ variant: 'danger', msg: 'Cannot save payment method. Please try again.' });
      this.$router.push({ name: this.$route.name });
      this.isLoading = false;
    } else {
      this.fetchPaymentMethod();
    }
  },
};
</script>
