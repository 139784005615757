<template>
  <div>
    <page-header
      :title="
        $route.params.entity ? `Request Partnership with ${get($route.params, 'entity.name', entityTypeName)}` : ''
      "
      :container-class="containerClass"
    />
    <div class="page-section">
      <div :class="containerClass">
        <page-separator title="" />

        <div class="mx-3 mb-4" v-if="getLoggedInUser.role_type === USER_ROLE_TYPES.EMPLOYER">
          Complete the form below to request a partnership with {{ get($route.params, 'entity.name', entityTypeName) }}.
          <span v-if="partnershipFee.recurring_fee">
            A monthly payment of <b>${{ $n(partnershipFee.recurring_fee) }}</b> will be billed to
            <i v-if="isPayMethodLoading">Loading...</i>
            <b v-else-if="paymentMethod">card ending with {{ get(paymentMethod, 'last_four') }}</b>
            <a href="#" @click.prevent="addPaymentMethodClick" v-else class="text-primary">Add payment</a> on the date
            the partnership has been accepted.
          </span>
          <div><b>Note:</b> Any additional fees found below may apply.</div>
        </div>
        <div class="mx-3 mb-4" v-else>
          Complete the form below to request a partnership with {{ get($route.params, 'entity.name', entityTypeName) }}.
        </div>

        <div class="card">
          <!-- <div class="card-header">
          <h5 class="my-0">Request Partnership</h5>
        </div> -->
          <div class="card-body">
            <b-form @submit.prevent="onSubmit">
              <!-- Fees section (Use if needed) -->
              <!-- <div class="mb-2 d-flex align-items-start">
                <span class="card-title fw600">
                  Partnership Fees{{ getLoggedInUser.role_type === USER_ROLE_TYPES.SCHOOL ? '*' : '' }}
                </span>
              </div>

              <b-skeleton-wrapper :loading="isFeeLoading">
                <template #loading>
                  <div class="mb-2">
                    <b-skeleton width="60%"></b-skeleton>
                    <b-skeleton width="80%"></b-skeleton>
                  </div>
                </template>

                <div class="d-flex align-items-start">
                  <span
                    ><b>Initiation Fee:</b>

                    ${{ $n(partnershipFee.computed_fee) }} (${{ $n(partnershipFee.initial_fee) }}
                    / Recently Active Student
                    <i
                      class="material-icons icon-16pt clickable-item text-black-50"
                      v-b-popover.hover.top="ACTIVE_STD_INFO_DESC"
                      >info</i
                    >)
                  </span>
                </div>

                <div
                  class="d-flex align-items-start"
                  :class="{ 'mb-2': getLoggedInUser.role_type === USER_ROLE_TYPES.EMPLOYER }"
                >
                  <span><b>Recurring Fee:</b> ${{ $n(partnershipFee.recurring_fee) }} / Month</span>
                </div>
              </b-skeleton-wrapper>

              <div class="d-flex align-items-start mb-2" v-if="getLoggedInUser.role_type === USER_ROLE_TYPES.SCHOOL">
                * Will be charged to employer.
              </div> -->

              <!-- End fees section -->

              <div class="mb-2 d-flex align-items-start">
                <span class="card-title fw600">Programs</span>
              </div>
              <b-form-group>
                <v-select
                  id="program"
                  class="form-control v-select-custom"
                  label="title"
                  v-model="programs"
                  :reduce="(program) => program.id"
                  placeholder="Select Program"
                  :options="allPrograms"
                  :loading="areProgramsLoading"
                  multiple
                  :disabled="isFormLoading"
                  :class="!$v.programs.required && $v.programs.$dirty ? 'form-control is-invalid' : ''"
                >
                  <template #search="{ attributes, events }">
                    <input class="vs__search" :required="!programs.length" v-bind="attributes" v-on="events" />
                  </template>
                  <template slot="option" slot-scope="option">
                    <span>{{ option.title }}</span>
                  </template>
                </v-select>
                <b-form-text> <b>Note:</b> Programs cannot be changed midst of a partnership agreement.</b-form-text>
                <b-form-invalid-feedback :state="!$v.programs.required && $v.programs.$dirty ? false : null"
                  >This field is required.</b-form-invalid-feedback
                >
              </b-form-group>

              <div class="mb-2 d-flex align-items-start">
                <span class="card-title fw600">Type</span>
              </div>
              <b-form-group>
                <v-select
                  id="type"
                  class="form-control v-select-custom"
                  label="text"
                  v-model="type"
                  :reduce="(type) => type.value"
                  placeholder="Select type"
                  :options="allTypes"
                  :disabled="isFormLoading"
                  :clearable="false"
                  :class="!$v.type.required && $v.type.$dirty ? 'form-control is-invalid' : ''"
                >
                  <template #search="{ attributes, events }">
                    <input class="vs__search" :required="!type" v-bind="attributes" v-on="events" />
                  </template>
                  <template slot="option" slot-scope="option">
                    <span>{{ option.text }}</span>
                  </template>
                </v-select>
                <!-- <b-form-text> <b>Note:</b> Programs cannot be changed midst of a partnership agreement.</b-form-text> -->
                <b-form-invalid-feedback :state="!$v.type.required && $v.type.$dirty ? false : null"
                  >This field is required.</b-form-invalid-feedback
                >
              </b-form-group>

              <div class="mb-2 d-flex align-items-start">
                <span class="card-title fw600">Message</span>
              </div>
              <b-form-group>
                <b-form-textarea
                  class="form-control"
                  rows="10"
                  placeholder="Write a message to send."
                  v-model="message"
                  required
                  :disabled="isFormLoading"
                  :state="!$v.message.required && $v.message.$dirty ? false : null"
                ></b-form-textarea>
                <b-form-invalid-feedback :state="!$v.message.required && $v.message.$dirty ? false : null"
                  >This field is required.</b-form-invalid-feedback
                >
              </b-form-group>

              <div v-if="getLoggedInUser.role_type === USER_ROLE_TYPES.EMPLOYER">
                <div class="mb-2 d-flex align-items-start">
                  <span class="card-title fw600">Legal Terms</span>
                </div>

                <div class="mb-2 d-flex align-items-start">
                  <span>
                    <span
                      v-if="get(partnershipFee, 'recurring_fee') !== 0 || get(partnershipFee, 'computed_fee') !== 0"
                    >
                      In addition to the
                      <span v-if="get(partnershipFee, 'recurring_fee') !== 0">recurring fee </span
                      ><i v-if="isFeeLoading">(Loading...)</i
                      ><span v-else-if="partnershipFee && get(partnershipFee, 'recurring_fee') !== 0">
                        (${{ get(partnershipFee, 'recurring_fee') }})</span
                      >
                      <span
                        v-if="get(partnershipFee, 'recurring_fee') !== 0 && get(partnershipFee, 'computed_fee') !== 0"
                      >
                        and
                      </span>
                      <span v-if="get(partnershipFee, 'computed_fee') !== 0"> initiation fee</span>
                      <i v-if="isFeeLoading">(Loading...)</i
                      ><span v-else-if="partnershipFee && get(partnershipFee, 'computed_fee') !== 0">
                        (${{ get(partnershipFee, 'computed_fee') }})</span
                      >, you
                    </span>
                    <span v-else>You</span> agree to pay reference fee <i v-if="isFeeLoading">(Loading...)</i
                    ><span v-else-if="partnershipFee && get(partnershipFee, 'reference_fee') !== 0">
                      (${{ get(partnershipFee, 'reference_fee') }})</span
                    ><span v-else>(if any)</span> if you hire a candidate after seeing them on our Career Center. This
                    includes candidates you see but do not message, and includes contractors as well as full-time
                    employees.
                  </span>
                </div>

                <div class="mb-2 d-flex align-items-start" v-if="school.partnership_terms">
                  <b>
                    <a href="#" @click.prevent="showTermsModal = true" class="text-primary"
                      >View full terms of Service</a
                    >
                  </b>
                </div>

                <b-form-group label-class="form-label" v-if="partnershipFee.computed_fee || isFeeLoading">
                  <b-form-checkbox v-model="initFeeAgree" name="check-button" :disabled="isFormLoading">
                    <div style="font-weight: initial">I agree to the following initiation fee:</div>

                    <b-skeleton-wrapper :loading="isFeeLoading">
                      <template #loading>
                        <b-skeleton width="80%"></b-skeleton>
                      </template>
                      <b>${{ $n(partnershipFee.computed_fee) }} billed once</b>
                    </b-skeleton-wrapper>
                  </b-form-checkbox>
                </b-form-group>

                <b-form-group label-class="form-label" v-if="partnershipFee.reference_fee || isFeeLoading">
                  <b-form-checkbox v-model="refFeeAgree" name="check-button" :disabled="isFormLoading">
                    <div style="font-weight: initial">I agree to the following reference fee:</div>

                    <b-skeleton-wrapper :loading="isFeeLoading">
                      <template #loading>
                        <b-skeleton width="80%"></b-skeleton>
                      </template>
                      <b>${{ $n(partnershipFee.reference_fee) }} per hire</b>
                    </b-skeleton-wrapper>
                  </b-form-checkbox>
                </b-form-group>

                <b-form-group label-class="form-label">
                  <b-form-checkbox v-model="termsAndConditions" name="check-button" :disabled="isFormLoading">
                    <p style="font-weight: initial">I understand and accept the terms of services.</p>
                  </b-form-checkbox>
                </b-form-group>
              </div>
              <b-form-group label="Signature" label-for="signature" label-class="form-label">
                <b-form-input
                  id="signature"
                  placeholder="Add Signature"
                  v-model="signature"
                  :state="!$v.signature.required && $v.signature.$dirty ? false : null"
                  maxlength="60"
                />

                <b-form-invalid-feedback v-if="!$v.signature.required">This field is required.</b-form-invalid-feedback>
                <b-form-text>
                  <b>Note:</b> A signed email copy will be sent to you once the other party has signed.</b-form-text
                >
              </b-form-group>

              <div class="text-dark" v-if="signature">Preview</div>
              <h1 class="signature-font">{{ signature }}</h1>

              <div class="d-flex justify-content-end">
                <b-btn
                  variant="primary"
                  class="btn-normal"
                  style="width: 150px"
                  :disabled="
                    getLoggedInUser.role_type === USER_ROLE_TYPES.EMPLOYER
                      ? isFormLoading || !areAllAgree
                      : isFormLoading
                  "
                  type="submit"
                  ><i v-if="isLoading" class="fas fa-circle-notch fa-spin"></i> <span v-else>Request</span></b-btn
                >
              </div>
            </b-form>
          </div>
        </div>
      </div>
    </div>

    <partnership-terms-modal
      :school="school"
      :show-modal="showTermsModal"
      @close="showTermsModal = false"
    ></partnership-terms-modal>
  </div>
</template>


<script>
import { get } from 'lodash';
import { mapGetters, mapActions } from 'vuex';
import PageHeader from '@/components/Ui/PageHeader.vue';
import PageSeparator from '@/components/Ui/PageSeparator.vue';
import Page from '../../components/Page.vue';
import PartnershipTermsModal from './PartnershipTermsModal.vue';
import 'vue-swatches/dist/vue-swatches.css';
import { required } from 'vuelidate/lib/validators';
import { USER_ROLE_TYPES, ACTIVE_STD_INFO_DESC } from '../../common/constants';

export default {
  components: {
    PageHeader,
    PageSeparator,
    PartnershipTermsModal,
    // FmvAvatar,
    //  UserAvatar,
  },
  extends: Page,

  data() {
    return {
      title: 'Request Partnership',
      USER_ROLE_TYPES,

      isLoading: false,
      message: null,
      programs: [],
      school: {},
      allPrograms: [],
      partnershipFee: {},
      paymentMethod: null,
      signature: '',
      areProgramsLoading: false,
      isFeeLoading: false,
      isPayMethodLoading: false,

      termsAndConditions: false,
      initFeeAgree: false,
      refFeeAgree: false,

      ACTIVE_STD_INFO_DESC,
      showTermsModal: false,
      type: 'recruitment',
      allTypes: [
        { text: 'Clinical', value: 'clinical' },
        { text: 'Recruitment', value: 'recruitment' },
      ],
    };
  },
  validations() {
    return {
      programs: { required },
      signature: { required },
      message: { required },
      type: { required },
    };
  },

  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),
    isFormLoading() {
      return this.isLoading || this.isFeeLoading || this.areProgramsLoading;
    },
    areAllAgree() {
      return this.termsAndConditions && this.initFeeAgree && this.refFeeAgree;
    },
    entityTypeName() {
      return this.getLoggedInUser.role_type === USER_ROLE_TYPES.SCHOOL ? 'School' : 'Employer';
    },
  },

  methods: {
    ...mapActions('partnership', [
      'createPartnership',
      'getCalcPartnershipFee',
      'requestWithPaymentDetails',
      'requestPartnershipPaymentSuccess',
      'requestPartnershipPaymentCancel',
    ]),
    ...mapActions('program', ['getAllPrograms']),
    ...mapActions('payments', ['verifyPaymentInfo', 'getDefaultPaymentMethod']),
    ...mapActions('school', ['getSchool']),
    get,

    async fetchPartnershipFee() {
      this.isFeeLoading = true;
      const response = await this.getCalcPartnershipFee({
        school:
          this.getLoggedInUser.role_type === USER_ROLE_TYPES.SCHOOL
            ? this.getLoggedInUser.linked_entity.id
            : this.$route.params.schoolId,
      });

      this.partnershipFee = response.data;
      this.initFeeAgree = !this.partnershipFee.computed_fee;
      this.refFeeAgree = !this.partnershipFee.reference_fee;

      this.isFeeLoading = false;
    },

    async fetchPrograms() {
      this.areProgramsLoading = true;
      const response = await this.getAllPrograms({
        school:
          this.getLoggedInUser.role_type === USER_ROLE_TYPES.SCHOOL
            ? this.getLoggedInUser.linked_entity.id
            : this.$route.params.schoolId,
        ordering: 'id',
      });

      this.allPrograms = response.data;
      this.areProgramsLoading = false;
    },

    async fetchSchool() {
      try {
        const resp = (await this.getSchool(this.$route.params.schoolId)).data;
        this.school = resp;
      } catch (err) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
    },

    async fetchPaymentMethod() {
      this.isPayMethodLoading = true;
      try {
        this.paymentMethod = get((await this.getDefaultPaymentMethod()).data, 'data');
      } catch (err) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
      this.isPayMethodLoading = false;
    },

    async addPaymentMethodClick() {
      const isPayConfirmed = await this.openNoPaymentConfirmation();
      if (isPayConfirmed) {
        this.$router.push({ name: 'manage-payment-method' });
      }
    },

    async openNoPaymentConfirmation(okTitle = 'Add Payment') {
      return this.$bvModal.msgBoxConfirm(
        'You must have a payment method on file before submitting request. You will not be charged until a partnership has been approved.',
        {
          title: 'Add Payment',
          size: 'md',
          buttonSize: 'sm',
          okVariant: 'success',
          cancelVariant: 'danger',
          okTitle,
          cancelTitle: 'Cancel',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true,
        }
      );
    },

    async onSubmit() {
      this.isLoading = true;
      let isPayConfirmed = true;
      this.$v.$touch();
      if (!this.$v.$invalid) {
        if (
          (this.partnershipFee.computed_fee || this.partnershipFee.recurring_fee) &&
          !this.paymentMethod &&
          this.getLoggedInUser.role_type === USER_ROLE_TYPES.EMPLOYER
        ) {
          isPayConfirmed = await this.openNoPaymentConfirmation('Add Payment & Request');
        }

        if (isPayConfirmed) {
          try {
            let htmlMessage = this.message;
            const htmlEntities = [
              { regex: '&', entity: '&amp;' },
              { regex: '>', entity: '&gt;' },
              { regex: '<', entity: '&lt;' },
            ];
            htmlEntities.forEach((ent) => {
              const reg = new RegExp(ent.regex, 'g');
              htmlMessage = htmlMessage.replace(reg, ent.entity);
            });
            htmlMessage = htmlMessage.replace(/(?:\r\n|\r|\n)/g, '<br>');
            if (this.getLoggedInUser.role_type === USER_ROLE_TYPES.EMPLOYER) {
              let paymentVerified = true;

              // Verify payment if any fee has to be paid.
              if (this.partnershipFee.computed_fee || this.partnershipFee.recurring_fee) {
                const resp = await this.verifyPaymentInfo();
                paymentVerified = resp.data.payment_info_available;
              }

              if (paymentVerified) {
                await this.createPartnership({
                  request_to_type: this.$route.params.type,
                  message: htmlMessage,
                  request_to_id: this.$route.params.entity.id,
                  programs: this.programs,
                  type: this.type,
                  signature_from: this.signature,
                });
                this.makeToast({ variant: 'success', msg: 'Request Sent!' });
                if (this.getLoggedInUser.role_type === USER_ROLE_TYPES.EMPLOYER)
                  setTimeout(
                    () =>
                      this.$router.push({ name: 'emp-school-profile', params: { id: this.$route.params.entity.id } }),
                    250
                  );
                else
                  setTimeout(
                    () => this.$router.push({ name: 'schl-employer-profile', params: { id: this.$route.params.id } }),
                    250
                  );

                this.isLoading = false;
              } else {
                const resp = await this.requestWithPaymentDetails({
                  request_to_type: this.$route.params.type,
                  message: htmlMessage,
                  request_to_id: this.$route.params.entity.id,
                  programs: this.programs,
                  signature_from: this.signature,
                });
                window.location.href = resp.data.data;
              }
            } else if (this.getLoggedInUser.role_type === USER_ROLE_TYPES.SCHOOL) {
              await this.createPartnership({
                request_to_type: this.$route.params.type,
                message: htmlMessage,
                request_to_id: this.$route.params.entity.id,
                programs: this.programs,
                signature_from: this.signature,
              });
              this.makeToast({ variant: 'success', msg: 'Request Sent!' });
              if (this.getLoggedInUser.role_type === USER_ROLE_TYPES.EMPLOYER)
                setTimeout(
                  () => this.$router.push({ name: 'emp-school-profile', params: { id: this.$route.params.entity.id } }),
                  250
                );
              else
                setTimeout(
                  () => this.$router.push({ name: 'schl-employer-profile', params: { id: this.$route.params.id } }),
                  250
                );
            }
          } catch (err) {
            this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });

            this.isLoading = false;
          }
        }
      } else {
        this.makeToast({ variant: 'danger', msg: 'Please fill all fields correctly.' });
      }
      this.isLoading = false;
    },
  },

  async mounted() {
    this.isFeeLoading = true;
    this.fetchSchool();
    if (this.$route.query.paymentSuccess) {
      const draftId = this.$route.query.draftId;
      const stripeSessionId = this.$route.query.stripeSessionId;
      await this.requestPartnershipPaymentSuccess({ draft_id: draftId, session_id: stripeSessionId });
      this.makeToast({ variant: 'success', msg: 'Request Sent!' });
      if (this.getLoggedInUser.role_type === USER_ROLE_TYPES.EMPLOYER)
        setTimeout(() => this.$router.push({ name: 'emp-school-profile', params: { id: this.$route.params.id } }), 250);
      else
        setTimeout(
          () => this.$router.push({ name: 'schl-employer-profile', params: { id: this.$route.params.id } }),
          250
        );
    } else if (this.$route.query.paymentFailed) {
      const draftId = this.$route.query.draftId;
      await this.requestPartnershipPaymentCancel({ draft_id: draftId });
      this.makeToast({ variant: 'danger', msg: 'Cannot send partnership request. Please try again!' });
      if (this.getLoggedInUser.role_type === USER_ROLE_TYPES.EMPLOYER)
        setTimeout(() => this.$router.push({ name: 'emp-school-profile', params: { id: this.$route.params.id } }), 250);
      else
        setTimeout(
          () => this.$router.push({ name: 'schl-employer-profile', params: { id: this.$route.params.id } }),
          250
        );
    } else {
      if (!this.$route.params.entity) {
        if (this.getLoggedInUser.role_type === USER_ROLE_TYPES.EMPLOYER)
          this.$router.push({ name: 'emp-school-profile', params: { id: this.$route.params.id } });
        else this.$router.push({ name: 'schl-employer-profile', params: { id: this.$route.params.id } });
      } else {
        this.message = `Hi ${this.$route.params.entity.name},

My name is ${this.getLoggedInUser.first_name} ${this.getLoggedInUser.last_name} and I work for ${this.getLoggedInUser.linked_entity.name}. We would like to add you as our partner. Looking forward to working with you soon.

Best,
${this.getLoggedInUser.linked_entity.name}`;

        if (this.getLoggedInUser.role_type === USER_ROLE_TYPES.EMPLOYER) {
          this.fetchPaymentMethod();
        }
        this.fetchPrograms();
        this.fetchPartnershipFee();
      }
    }
  },
};
</script>
