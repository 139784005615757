<template>
  <div :class="containerClass">
    <page-header title="Security" :container-class="null" class="mb-32pt" info-text="Manage your account password." />

    <b-form class="col-md-12 px-0 page-section pt-0" @submit.prevent="onPassUpdate">
      <b-form-group :label="$t('profileMsgs.currPassword')" label-for="currPassword" label-class="form-label">
        <b-form-input
          id="currPassword"
          :placeholder="$t('profileMsgs.currPassword')"
          v-model="passForm.currPassword"
          required
          type="password"
          :state="errorStates.currPass"
        />
        <b-form-invalid-feedback>{{ $t(`passValidationMsgs.${errorCode}`) }}</b-form-invalid-feedback>
      </b-form-group>

      <b-form-group :label="$t('profileMsgs.newPassword')" label-for="newPassword" label-class="form-label">
        <b-form-input
          id="newPassword"
          :placeholder="$t('profileMsgs.newPassword')"
          v-model="passForm.newPassword"
          required
          type="password"
          :state="errorStates.newPass"
        />
      </b-form-group>

      <b-form-group :label="$t('profileMsgs.repeatPassword')" label-for="repeatPassword" label-class="form-label">
        <b-form-input
          id="repeatPassword"
          :placeholder="$t('profileMsgs.repeatPassword')"
          v-model="passForm.repeatPassword"
          required
          type="password"
          :state="errorStates.repeatPass"
        />
        <b-form-invalid-feedback>{{ $t(`passValidationMsgs.${errorCode}`) }}</b-form-invalid-feedback>
      </b-form-group>

      <b-btn variant="primary" :disabled="isLoading" style="width: 150px" type="submit">
        <i v-if="isLoading" class="fas fa-circle-notch fa-spin"></i>
        <span v-else>{{ $t('generalMsgs.saveChanges') }}</span>
      </b-btn>
    </b-form>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import PageHeader from '@/components/Ui/PageHeader.vue';
import Page from '../../components/Page.vue';
import { PASSWORD_VALIDATIONS, PASSWORD_ALL_NUMERIC_REGEX } from '../../common/constants';

export default {
  components: { PageHeader },
  extends: Page,

  data() {
    return {
      title: 'Security',

      passForm: {
        currPassword: '',
        newPassword: '',
        repeatPassword: '',
      },
      isLoading: false,
      error: false,
      errorCode: null,
      errorStates: {
        currPass: null,
        newPass: null,
        repeatPass: null,
      },
    };
  },

  computed: {
    breadcrumb() {
      return [
        { text: this.$t('home'), to: this.routes.home },
        { text: 'Security', active: true },
      ];
    },
  },

  methods: {
    ...mapActions('auth', ['updateMyPassword']),

    async onPassUpdate() {
      this.isLoading = true;
      this.errorStates.newPass = this.errorStates.repeatPass = this.errorStates.currPass = null;

      if (this.passForm.newPassword !== this.passForm.repeatPassword) {
        this.errorCode = PASSWORD_VALIDATIONS.PASSWORDS_NOT_MATCH;
        this.errorStates.newPass = this.errorStates.repeatPass = false;
      } else if (this.passForm.newPassword.length < 5) {
        this.errorCode = PASSWORD_VALIDATIONS.PASSWORDS_MIN_LENGTH;
        this.errorStates.newPass = this.errorStates.repeatPass = false;
      } else if (PASSWORD_ALL_NUMERIC_REGEX.test(this.passForm.newPassword)) {
        this.errorCode = PASSWORD_VALIDATIONS.PASSWORDS_ALL_NUMERIC;
        this.errorStates.newPass = this.errorStates.repeatPass = false;
      } else {
        try {
          await this.updateMyPassword({
            old_password: this.passForm.currPassword,
            new_password: this.passForm.newPassword,
          });

          this.passForm.currPassword = this.passForm.newPassword = this.passForm.repeatPassword = '';
          this.makeToast({ variant: 'success', msg: this.$t('profileMsgs.passwordUpdated') });
        } catch (err) {
          if (err.response.status === 403) {
            this.errorCode = PASSWORD_VALIDATIONS.INCORRECT_CURR_PASS;
            this.errorStates.currPass = false;
          }
          this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
        }
      }
      this.isLoading = false;
    },
  },

  async mounted() {},
};
</script>
