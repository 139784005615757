<template>
  <div>
    <page-header :title="title" :container-class="containerClass" :breadcrumb="breadcrumb" />
    <div class="page-section">
      <div :class="containerClass">
        <page-separator title="" />
        <b-skeleton-wrapper :loading="isLoading">
          <template #loading>
            <div class="mb-2">
              <b-skeleton width="60%"></b-skeleton>
              <b-skeleton width="80%"></b-skeleton>
            </div>
          </template>

          <div class="d-flex align-items-start">
            <div v-safe-html="school.partnership_terms" />
          </div>
        </b-skeleton-wrapper>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import PageHeader from '@/components/Ui/PageHeader.vue';
import PageSeparator from '@/components/Ui/PageSeparator.vue';
import Page from '../../components/Page.vue';
import { get } from 'lodash';

export default {
  components: {
    PageHeader,
    PageSeparator,
  },
  extends: Page,
  data() {
    return {
      title: 'Partnership Terms of Service',
      isLoading: false,
      school: {},
    };
  },

  computed: {
    breadcrumb() {
      return [
        { text: this.$t('home'), to: this.routes.home },
        { text: 'Schools', to: { name: 'emp-schools-list' } },
        {
          text: get(this.school, 'name', 'School Profile'),
          to: { name: 'emp-school-profile', params: { id: this.$route.params.id } },
        },
        { text: 'Partnership Terms of Service', active: true },
      ];
    },
  },

  methods: {
    ...mapActions('school', ['getSchool']),

    async fetchSchool() {
      this.isLoading = true;

      try {
        const resp = (await this.getSchool(this.$route.params.id)).data;
        this.school = resp;
      } catch (err) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }

      this.isLoading = false;
    },
  },
  async mounted() {
    if (this.$route.params.id) {
      this.fetchSchool();
    }
  },
};
</script>
