<template>
  <div v-if="paymnetStatus">
    <plan-payment-success
      :isPayLoading="isPayLoading"
      :paymentSuccess="paymentSuccess"
      :program="get(plan, 'program_name')"
    />
  </div>
  <div v-else>
    <page-header :title="title" :container-class="containerClass" />
    <payment-plan-details :plan="plan" :isLoading="isLoading" />
  </div>
</template>

<script>
import { get } from 'lodash';
import PageHeader from '@/components/Ui/PageHeader.vue';
import Page from '@/components/Page.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
  components: {
    PageHeader,
    PaymentPlanDetails: () => import('./PaymentPlanDetails.vue'),
    PlanPaymentSuccess: () => import('./PlanPaymentSuccess.vue'),
  },
  extends: Page,

  name: 'PlanDetails',

  data() {
    return {
      title: 'Plan Details',
      windowWidth: window.innerWidth,
      isLoading: true,
      plan: {},
      isPayLoading: true,
      paymentSuccess: false,
      paymnetStatus: false,
    };
  },

  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),
    ...mapGetters('school', ['getCurrentSchool']),

    isMobileScreen() {
      return this.windowWidth <= 576;
    },
  },

  methods: {
    ...mapActions('payments', ['getSingleHEPaymentPlan', 'getDefaultPaymentMethod', 'paymentPlanSuccess']),

    get,

    handleResize() {
      this.windowWidth = window.innerWidth;
    },

    async fetchHEPaymentPlan() {
      try {
        const res = await this.getSingleHEPaymentPlan({
          plan_id: this.$route.params.id,
        });

        this.plan = res.data;
      } catch (e) {
        // USE ERROR
      }
      this.isLoading = false;
    },
  },

  async mounted() {
    window.addEventListener('resize', this.handleResize);

    this.fetchHEPaymentPlan();

    if (this.$route.query.checkoutSuccess) {
      this.paymnetStatus = true;
      try {
        const res = await this.paymentPlanSuccess({ plan_id: this.$route.params.id });

        if (res.data.status === 'succeeded') {
          this.paymentSuccess = true;
          this.isPayLoading = false;
        } else {
          this.paymentSuccess = false;
          this.isPayLoading = false;
        }
      } catch (e) {
        // USE ERROR
      }
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
};
</script>
