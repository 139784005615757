<template>
  <div>
    <page-header :title="title" :container-class="containerClass" infoText="View partnered organizations." />
    <div class="page-section">
      <div :class="containerClass">
        <div class="card mb-0">
          <div class="card-header">
            <div class="row align-items-center" style="white-space: nowrap">
              <!-- Search -->
              <div class="col-lg-auto">
                <form class="search-form search-form--light d-lg-inline-flex mb-8pt mb-lg-0" @submit.prevent="">
                  <b-form-input
                    class="w-lg-auto"
                    :placeholder="'Search Organizations'"
                    v-model="searchTerm"
                    @input="onSearch"
                  />
                  <b-btn variant="flush" type="submit">
                    <md-icon v-text="'search'" />
                  </b-btn>
                </form>
              </div>
            </div>
          </div>

          <!-- Schools Table -->
          <b-table
            :fields="tableColumns"
            :items="orgs"
            :busy="isLoading"
            head-variant="light"
            class="table-nowrap"
            hover
            responsive
            no-local-sorting
            @sort-changed="onSortChange"
            @row-clicked="onRowClick"
          >
            <template #cell(image_url)="data">
              <user-avatar
                slot="aside"
                size="md"
                rounded="false"
                :user="data.item"
                :fileUrl="data.item.image_url"
                :variant="'light'"
              >
              </user-avatar>
            </template>

            <template #head(actions)="">
              <span></span>
            </template>
            <template #cell(actions)="data">
              <a
                href="#"
                v-b-popover.hover.left
                title="Preview as student"
                @click.prevent="openPreviewModal(data.item)"
              >
                <md-icon class="text-info">remove_red_eye</md-icon>
              </a>
              <a
                href="#"
                class="ml-2"
                v-b-popover.hover.left
                title="Copy profile link"
                @click.prevent="copyPreviewLink(data.item)"
              >
                <md-icon :class="'text-info'">content_copy</md-icon>
              </a>
            </template>
          </b-table>

          <!-- Footer Pagination -->
          <div class="card-footer">
            <pagination
              v-model="currentPage"
              :total-rows="totalOrgs"
              :per-page="perPage"
              @change="onPageChange"
              aria-controls="orgs-table"
            />
          </div>
        </div>
      </div>
    </div>
    <university-preview-modal :show-modal="showAddMetaTagsModal" :org="org" @close="hidePreviewModal" />
  </div>
</template>

<script>
import PageHeader from '@/components/Ui/PageHeader.vue';
import { debounce, get } from 'lodash';
import { mapActions, mapGetters } from 'vuex';

import Page from '@/components/Page.vue';
import Pagination from '@/components/Ui/Pagination.vue';
import MdIcon from '../../components/Ui/MdIcon.vue';
import { DEFAULT_PAGE_SIZE, USER_ROLE_TYPES, USER_ROLES } from '../../common/constants';
import { formatDateTime, formatFullDate } from '../../common/utils';
import UserAvatar from '../../components/User/UserAvatar.vue';
import UniversityPreviewModal from './UniversityPreviewModal.vue';

export default {
  components: { MdIcon, PageHeader, Pagination, UserAvatar, UniversityPreviewModal },
  extends: Page,

  name: 'OrgsList',

  data() {
    return {
      title: 'Organizations',

      windowWidth: window.innerWidth,
      isLoading: false,
      isResendingAll: false,

      perPage: DEFAULT_PAGE_SIZE,
      currentPage: 1,
      totalOrgs: 0,
      orgs: [],
      org: {},
      searchTerm: '',
      USER_ROLE_TYPES,
      isDownloading: false,
      stdCount: {},
      showStdModal: false,
      employer: null,
      USER_ROLES,
      showAddMetaTagsModal: false,
      university: {},
      isPreviewLinkCopied: false,
    };
  },

  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),
    ...mapGetters('school', ['getCurrentSchool']),
    breadcrumb() {
      return [
        { text: 'Home', to: this.routes.home },
        { text: 'Manage Organizations', active: true },
      ];
    },

    isMobileScreen() {
      return this.windowWidth <= 776;
    },
    tableColumns() {
      return [
        { key: 'image_url', label: '' },
        { key: 'name', label: 'Name', tdClass: 'clickable-item' },

        { key: 'analytics.impressions', label: 'Impressions', tdClass: 'clickable-item text-center' },
        { key: 'analytics.views', label: 'Views', tdClass: 'clickable-item text-center' },
        {
          key: 'actions',
          tdClass: 'text-right ',
          thStyle: { minWidth: '100px' },
        },
      ];
    },
  },

  methods: {
    ...mapActions('organization', ['getSchPartneredOrgsList']),
    formatDateTime,
    formatFullDate,
    get,

    onRowClick(data) {
      window.open(data.organization_site.wbst_domain);
    },

    async copyPreviewLink(org) {
      try {
        const url = `${org.organization_site.wbst_domain}${
          org.organization_site.wbst_domain.endsWith('/') ? '' : '/'
        }training-providers/${this.getCurrentSchool.slug}/?preview=1`;
        await navigator.clipboard.writeText(url);
        this.isPreviewLinkCopied = true;
        this.makeToast({ variant: 'success', msg: 'Url Copied!' });

        setTimeout(() => (this.isPreviewLinkCopied = false), 2000);
      } catch ($e) {
        this.makeToast({ variant: 'danger', msg: 'Unable to copy.' });
      }
    },
    openPreviewModal(org) {
      this.org = org;
      this.showAddMetaTagsModal = true;
    },
    hidePreviewModal() {
      this.showAddMetaTagsModal = false;
    },
    async fetchOrgs() {
      this.isLoading = true;

      const response = await this.getSchPartneredOrgsList({
        ...(this.searchTerm && { search: this.searchTerm }),
      });

      this.orgs = response.data;
      this.isLoading = false;
    },

    onPageChange(pageNum) {
      this.fetchOrgs(pageNum);
    },

    onSortChange(context) {
      this.ordering = context.sortDesc ? '-' + context.sortBy : context.sortBy;
      this.fetchOrgs();
    },
    handleResize() {
      this.windowWidth = window.innerWidth;
    },
    onSearch() {
      this.debouncedSearchOrgs(this);
    },

    debouncedSearchOrgs: debounce((vm) => {
      vm.fetchOrgs();
    }, 500),
  },

  async mounted() {
    this.fetchOrgs();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
};
</script>
