<template>
  <div>
    <div v-if="uploadBtnOnly">
      <input type="file" accept="image/*" @change="onFileSelect" class="d-none" ref="uploader" />
      <b-button class="upload-button" @click.prevent="$refs.uploader.click()" style="width: 80px">
        <i v-if="isPictureLoading" class="fas fa-circle-notch fa-spin"></i> <span v-else>Upload</span>
      </b-button>
    </div>
    <div v-else>
      <b-form-file
        :placeholder="placeholder"
        :browse-text="$t('generalMsgs.browse')"
        @change="onFileSelect"
        accept="image/*"
        type="file"
        :disabled="isUploading"
        :required="isRequired"
        :state="isSizeExceeded || state ? false : null"
      />
    </div>
    <b-form-invalid-feedback :state="!isImageType ? false : null"
      >Please upload correct file format, only image files are accepted.</b-form-invalid-feedback
    >
    <b-form-invalid-feedback :state="isSizeExceeded ? false : null"
      >File too large. File must be less than 4 megabytes.</b-form-invalid-feedback
    >
    <b-form-invalid-feedback :state="state ? false : null">{{ stateMessage }}</b-form-invalid-feedback>
  </div>
</template>


<script>
export default {
  props: {
    isUploading: {
      type: Boolean,
      default: false,
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: 'Select Image',
    },
    uploadBtnOnly: {
      type: Boolean,
      default: false,
    },
    isPictureLoading: {
      type: Boolean,
      default: false,
    },
    state: {
      type: Boolean,
      default: null,
    },
    stateMessage: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isSizeExceeded: false,
      isImageType: true,
    };
  },
  methods: {
    onFileSelect(evt) {
      let file = evt.target.files[0];

      const MAX_WIDTH = file.width;
      const MAX_HEIGHT = file.height;

      if (file.type.split('/')[0] !== 'image') {
        this.isImageType = false;
      } else {
        this.isImageType = true;
      }

      if (file.size / 1024 > 4096) {
        this.isSizeExceeded = true;
        return;
      } else {
        this.isSizeExceeded = false;
      }

      let self = this;
      let reader = new FileReader();

      let img = document.createElement('img');

      reader.onload = function (e) {
        img.src = e.target.result;
        let canvas = document.createElement('canvas');

        img.onload = function () {
          let width = img.width ? img.width : MAX_WIDTH;
          let height = img.height ? img.height : MAX_HEIGHT;

          if (width > height) {
            if (width > MAX_WIDTH) {
              height *= MAX_WIDTH / width;
              width = MAX_WIDTH;
            }
          } else {
            if (height > MAX_HEIGHT) {
              width *= MAX_HEIGHT / height;
              height = MAX_HEIGHT;
            }
          }
          canvas.width = width;
          canvas.height = height;
          let ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0, width, height);

          let dataurl = canvas.toDataURL(file.type);
          const compressedImage = self.dataURLtoFile(dataurl, file.name);
          if (compressedImage.size >= file.size) {
            self.$emit('image', file);
          } else {
            self.$emit('image', compressedImage);
          }
        };
      };
      reader.readAsDataURL(file);
    },

    dataURLtoFile(dataurl, filename) {
      let arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[arr.length - 1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    },
  },
};
</script>
