<template>
  <div :class="containerClass">
    <page-header title="Account" :container-class="null" class="mb-32pt" info-text="Manage your profile" />

    <b-form class="col-md-12 px-0 page-section pt-0" @submit.prevent="onProfileUpdate">
      <b-form-group
        :label="$t('userMsgs.avatar')"
        label-for="avatar"
        label-class="form-label"
        class="row-align-items-center"
      >
        <b-media class="align-items-center" vertical-align="center">
          <user-avatar
            slot="aside"
            :user="user"
            rounded="lg"
            size="lg"
            :isLoading="isUploading"
            :file-url="profile.avatarUrl"
            variant="dark"
          >
          </user-avatar>

          <image-uploader
            @image="setImage"
            :isUploading="isUploading"
            :isRequired="false"
            :placeholder="$t('userMsgs.avatarDesc')"
          />
        </b-media>
      </b-form-group>

      <div class="row">
        <b-form-group :label="$t('userMsgs.firstName')" label-for="firstname" label-class="form-label" class="col-md-6">
          <b-form-input id="firstname" :placeholder="$t('userMsgs.firstName')" v-model="profile.firstName" required />
        </b-form-group>

        <b-form-group :label="$t('userMsgs.lastName')" label-for="lastname" label-class="form-label" class="col-md-6">
          <b-form-input id="lastname" :placeholder="$t('userMsgs.lastName')" v-model="profile.lastName" required />
        </b-form-group>
      </div>

      <b-form-group :label="$t('authMsgs.emailAddr')" label-for="email" label-class="form-label">
        <b-form-input id="email" :placeholder="$t('authMsgs.emailAddr')" :value="profile.email" disabled type="email" />
      </b-form-group>

      <b-form-group :label="$t('userMsgs.phone')" label-for="phone" label-class="form-label">
        <b-form-input id="phone" placeholder="(888) 689 - 1235" v-model="profile.phone" v-mask="'(###) ### - ####'" />
      </b-form-group>

      <b-form-group :label="$t('userMsgs.bio')" label-for="about" label-class="form-label">
        <b-form-textarea id="about" :placeholder="`${$t('userMsgs.bioPlaceholder')}`" :rows="5" v-model="profile.bio" />
      </b-form-group>

      <b-form-group
        label="Email Settings"
        label-for="email_subscription"
        label-class="form-label"
        v-if="getLoggedInUser.role != USER_ROLES.SUPER_ADMIN"
      >
        <b-form-checkbox name="check-button" switch v-model="profile.subscription_status">
          Subscribe Email?
        </b-form-checkbox>
      </b-form-group>

      <b-btn variant="primary" :disabled="isLoading || isUploading" style="width: 150px" type="submit">
        <i v-if="isLoading" class="fas fa-circle-notch fa-spin"></i>
        <span v-else>{{ $t('generalMsgs.saveChanges') }}</span>
      </b-btn>
    </b-form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import axios from 'axios';

import Page from '../../components/Page.vue';
import UserAvatar from '../../components/User/UserAvatar.vue';
import { USER_ROLES, EMAIL_SUBSCRIPTION_STATUS } from '../../common/constants';
import PageHeader from '@/components/Ui/PageHeader.vue';
import ImageUploader from '@/components/ImageUploader.vue';

export default {
  components: {
    UserAvatar,
    PageHeader,
    ImageUploader,
  },
  extends: Page,

  data() {
    return {
      title: this.$t('profileMsgs.manageProfile'),
      profile: {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        avatarUrl: '',
        bio: '',
        subscription_status: true,
      },
      user: {},
      isLoading: false,
      isUploading: false,

      avatarFile: null,
      uploadPercent: 0,
      uploadCancelTokenSource: null,
      USER_ROLES,
    };
  },

  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),
    breadcrumb() {
      return [
        { text: this.$t('home'), to: this.routes.home },
        { text: this.$t('profileMsgs.manageProfile'), active: true },
      ];
    },
  },

  methods: {
    ...mapActions('auth', ['getMe', 'updateMyProfile', 'getUserAvatarPresignedUrl']),
    ...mapActions('s3Upload', ['uploadToPresignedUrl']),

    setImage(file) {
      this.avatarFile = file;
      this.uploadAvatar();
    },

    async onProfileUpdate() {
      this.isLoading = true;

      try {
        await this.updateMyProfile({
          first_name: this.profile.firstName,
          last_name: this.profile.lastName,
          phone: this.profile.phone,
          bio: this.profile.bio,
          avatar_url: this.profile.avatarUrl,
          subscription_status: this.profile.subscription_status ? 'enabled' : 'disabled',
        });
        this.makeToast({ variant: 'success', msg: this.$t('profileMsgs.profileUpdated') });
      } catch (e) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }

      this.isLoading = false;
    },

    async uploadAvatar() {
      this.uploadCancelTokenSource = axios.CancelToken.source();
      this.isUploading = true;

      try {
        const urlResp = await this.getUserAvatarPresignedUrl({
          file_name: this.avatarFile.name,
          content_type: this.avatarFile.type,
        });
        await this.uploadToPresignedUrl({
          url: urlResp.upload_url,
          file: this.avatarFile,
          config: {
            onUploadProgress: function (progressEvent) {
              this.uploadPercent = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            }.bind(this),
            cancelToken: this.uploadCancelTokenSource.token,
          },
        });
        this.profile.avatarUrl = urlResp.upload_url.split('?')[0];
      } catch (error) {
        this.avatarFile = null;
      }

      this.uploadCancelTokenSource = null;
      this.isUploading = false;
      this.uploadPercent = 0;
    },
  },

  async mounted() {
    this.isLoading = true;

    try {
      const resp = await this.getMe();
      this.user = resp;
      this.profile.firstName = resp.first_name;
      this.profile.lastName = resp.last_name;
      this.profile.email = resp.email;
      this.profile.phone = resp.phone;
      this.profile.bio = resp.bio;
      this.profile.avatarUrl = resp.avatar_url;
      this.profile.subscription_status = resp.subscription_status === EMAIL_SUBSCRIPTION_STATUS.ENABLED ? true : false;
    } catch (e) {
      this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
    }

    this.isLoading = false;
  },
};
</script>
